import React from 'react';
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import {store} from "./store";
import App from "./App";
import {appMonitor} from "./util/analytics";

import {rumConfig} from './AuthenticatedRoute/Amplify';
import "./index.css";

appMonitor({
    appId: rumConfig.RUM_APP_ID,
    guestRoleArn: rumConfig.RUM_ROLE_ARN,
    identityPoolId: rumConfig.RUM_IDPOOL_ID,
    canCollectAnalytics: true,
});

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
)