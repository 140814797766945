import React, {useEffect} from 'react'

import {AppLayout} from "@amzn/awsui-components-react";
import PartnerForm from "../components/partner/partnerform/PartnerForm";
import HubNavigation from "../components/hubnavigation";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {notificationTypeEnum} from "../constants/Constants";
import Notifications from "../components/common/Notification";
// import Notifications from "../../Notification/Notification";


const PartnerEditPage: React.FC = () => {
    const notificationType = useSelector((state: RootState) => state.notification.notificationType);
    const content = useSelector((state: RootState) => state.notification.content);
    const eventType = useSelector((state: RootState) => state.notification.eventType);

    const [showNotification, setShowNotification] = React.useState(false);

    useEffect(() => {
        if (notificationType === notificationTypeEnum.EDITPARTNER) {
            setShowNotification(true);
        }
    }, [notificationType, content, eventType]);
    return (
        <div>
            <AppLayout
                contentType="form"
                headerSelector="#h"
                content={<PartnerForm editMode={true}/>}
                navigation={<HubNavigation activeHref="/partners"/>}
                notifications={<Notifications showNotification={showNotification} type={eventType} message={content} />}
            />
        </div>
    )
}

export default PartnerEditPage;


