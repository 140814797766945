import React from "react";
import {SpaceBetween} from "@amzn/awsui-components-react";
import {SolutionDefinition} from "../../../interfaces/solution.interface";
import BoxPaddedBottom from "../../common/BoxPaddedBottom";
import {PartnerDefinition} from "../../../interfaces/partner.interface";
import {MailTo} from "../../common/MailTo";



interface SolutionPeopleProps {
    solution: SolutionDefinition;
    partner: PartnerDefinition
}

const SolutionPeople: React.FC<SolutionPeopleProps> = ({ solution, partner }) => {

    return (
        <SpaceBetween size="l">
            {(partner.pdmName && partner.pdmEmail) &&
                <div>
                    <BoxPaddedBottom variant="awsui-key-label">Partner Development Manager</BoxPaddedBottom>
                    <div><MailTo mailto={partner.pdmEmail} label={partner.pdmName}/></div>
                </div>
            }
            {(solution.isvPsmName && solution.isvPsmEmail) &&
                <div>
                    <BoxPaddedBottom variant="awsui-key-label">ISV Co-Sell PSM</BoxPaddedBottom>
                    <div><MailTo mailto={solution.isvPsmEmail} label={solution.isvPsmName}/></div>
                </div>
            }
            {(solution.isvPsmName && solution.isvPsmEmail) &&
                <div>
                    <BoxPaddedBottom variant="awsui-key-label">External Partner Contact</BoxPaddedBottom>
                    <div><MailTo mailto={solution.partnerContactEmail} label={solution.partnerContactName}/></div>
                </div>
            }
        </SpaceBetween>
    )


}
export default SolutionPeople;