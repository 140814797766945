import {
    fundingTypeEnum,
    IFundingProgram,
    IFundingProgramProps,
    partnerTypeEnum,
    industryEnum
} from "./FundingProgramInterface";
import {PROGRAM_DETAILS} from "../../constants/ProgramDetails";

export class JumpstartFunding implements IFundingProgram {
    protected props: IFundingProgramProps;
    public programDetails = PROGRAM_DETAILS.JUMPSTART;
    public fundingAmount: number;
    public fundingType = fundingTypeEnum.IMPLEMENT;

    constructor(props: IFundingProgramProps) {
        this.props = props;
        this.fundingAmount = this.getFundingAmount();
    }

    protected getFundingAmount(): number {
        const fundingAmount = this.props.mrr * 3.5;
        if (fundingAmount < this.props.sowCost / 2) {
            return fundingAmount;
        } else {
            return this.props.sowCost / 2;
        }
    }

    isApproved(): boolean {
        if (this.props.partnerType !== partnerTypeEnum.SERVICES) {
            return false;
        }

        if (this.props.segment !== industryEnum.SUP) {
            return false;
        }

        if (this.props.migration) {
            return false;
        }

        if (this.props.serviceTypes.length < 1) {
            return false;
        }

        return true;
    }



}