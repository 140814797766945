import React, {useEffect} from 'react';
import {SpaceBetween} from "@amzn/awsui-components-react";
import FundingProgramView from "./FundingProgram";
import {
    fundingTypeEnum, IFundingProgram
} from "../../util/funding/FundingProgramInterface";

import {useLocation, useNavigate} from "react-router-dom";

const FundingResults: React.FC= () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [assessPrograms, setAssessPrograms] = React.useState<React.JSX.Element>();
    const [mobilizePrograms, setMobilizePrograms] = React.useState<React.JSX.Element>();
    const [implementPrograms, setImplementPrograms] = React.useState<React.JSX.Element>();

    useEffect( () => {
        const { state }: {state: IFundingProgram[]} = location;

        if (!state) {
            navigate("/funding");
        }

        const assessProgramsList = state.filter(item => item.fundingType === fundingTypeEnum.ASSESS);
        const mobilizeProgramsList = state.filter(item => item.fundingType === fundingTypeEnum.MOBILIZE);
        const implementProgramsList = state.filter(item => item.fundingType === fundingTypeEnum.IMPLEMENT);

        if (assessProgramsList.length > 0) {
            const assessProgramsComponent = <FundingProgramView
                fundingPrograms={assessProgramsList}
                fundingType="Assess Phase Funding Options"
            />
            setAssessPrograms(assessProgramsComponent);
        }

        if (mobilizeProgramsList.length > 0) {
            const mobilizeProgramsComponent = <FundingProgramView
                fundingPrograms={mobilizeProgramsList}
                fundingType="Mobilize Phase Funding Options"
            />
            setMobilizePrograms(mobilizeProgramsComponent);
        }

        if (implementProgramsList.length > 0) {
            const implementProgramsComponent = <FundingProgramView
                fundingPrograms={implementProgramsList}
                fundingType="Implement Phase Funding Options"
            />
            setImplementPrograms(implementProgramsComponent);
        }
    }, []);


    return (
        <div>
            <SpaceBetween direction="vertical" size="l">
                {(assessPrograms) && assessPrograms}
                {(mobilizePrograms) && mobilizePrograms}
                {(implementPrograms) && implementPrograms}
                {/*<CollectionHooksTable/>*/}
            </SpaceBetween>
        </div>
    );
}

export default FundingResults;