import React from "react";
import {createSlice} from "@reduxjs/toolkit";
import {FlashbarProps} from "@amzn/awsui-components-react";
import {notificationTypeEnum} from "../../constants/Constants";

type SliceState = {
    eventType:  FlashbarProps.Type | undefined;
    content: React.ReactNode,
    notificationType: notificationTypeEnum | undefined;
}

const initialState: SliceState = {
    eventType: undefined,
    content: "",
    notificationType: undefined
}

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotification(state, action) {
            state.content = action.payload.content;
            state.eventType = action.payload.eventType;
            state.notificationType = action.payload.notificationType;
        },
        resetNotification() {
            return initialState;
        }
    }
});

export const { setNotification, resetNotification } = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;