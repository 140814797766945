import React from "react";
import {Box, Cards, Link} from "@amzn/awsui-components-react";
import {PartnerDefinition} from "../../../interfaces/partner.interface";
import {useFetchSolutionByPartnerQuery} from "../../../store";
import {useNavigate} from "react-router-dom";
import {skipToken} from "@reduxjs/toolkit/query";

interface PartnerSpecificSolutionsProps {
    partner: PartnerDefinition | null;
}

const PartnerSpecificSolutions: React.FC<PartnerSpecificSolutionsProps> = ({partner}) => {
    const navigate = useNavigate();

    const { data: solutions, isLoading } = useFetchSolutionByPartnerQuery(partner ? partner.name : skipToken);

    const openDetailsPage = (event: any, item: any) => {
        event.preventDefault();
        const path = `/solution/${item.id}`;
        navigate(path);
    }

    return (
        <div>
            <Cards
                items={solutions || []}
                loading={isLoading}
                loadingText="Loading resources"
                cardDefinition={{
                    header: (item: any) => (
                        <div>
                            <Link fontSize="heading-m" onFollow={e => openDetailsPage(e, item)}>
                                {item.title}
                            </Link>
                        </div>
                    ),
                    sections: [
                        {
                            id: 'domain',
                            header: 'Solution Area',
                            content: item => item.domain,
                        }
                    ]
                }}
                empty={
                    <Box
                        textAlign="center"
                        color="inherit"
                    >
                        <b>No resources</b>
                        <Box
                            padding={{ bottom: "s" }}
                            variant="p"
                            color="inherit"
                        >
                            No resources to display.
                        </Box>
                    </Box>
                }
                />
        </div>
    )
}

export default PartnerSpecificSolutions;