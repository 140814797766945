import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {backendUrl} from "../../AuthenticatedRoute/Amplify";
import {PartnerDefinition} from "../../interfaces/partner.interface";
import {getIdToken} from "../../util/AuthService";


const partnerApi = createApi({
    reducerPath: 'partners',
    baseQuery: fetchBaseQuery({
        baseUrl: `${backendUrl}`,
        prepareHeaders: async(headers) => {
            const jwt = await getIdToken();
            headers.set('Authorization', `${jwt}`);
            return headers;
        }
    }),
    tagTypes: ['partner'],
    endpoints(builder) {
        return {
            fetchAllPartners: builder.query<PartnerDefinition[], void>({
                providesTags: (result) => {
                  if (result) {
                      let tags: any[];
                      tags = result.map(({name}) => {
                          return {type: 'partner', id: name};
                      });
                      tags.push({type: 'partner', id: 'LIST'});
                      return tags;
                  } else {
                      return [];
                  }
                },
                query: () => {
                    return {
                        url: '/partners',
                        method: 'GET'
                    };
                }
            }),
            fetchPartner: builder.query<PartnerDefinition, string>({
                providesTags: (result) => {
                    if (result) {
                        return [{type: 'partner', id: result.name}];
                    } else {
                        return [];
                    }
                },
                query: (name) => {
                    return {
                        url: `/partner/${name}`,
                        method: 'GET'
                    };
                }
            }),
            addPartner: builder.mutation<void, PartnerDefinition>({
                invalidatesTags: (_result, _error, {name}) => {
                    return [{type: 'partner', id: 'LIST'}, {type: 'partner', id: name}];
                },
                query: (partner) => {
                    return {
                        url: '/partners',
                        method: 'POST',
                        body: partner,
                        responseHandler: (response) => response.text(),
                    }
                }
            }),
            editPartner: builder.mutation<void, PartnerDefinition>({
                invalidatesTags: (_result, _error, {name}) => {
                    return [{type: 'partner', id: 'LIST'}, {type: 'partner', id: name}];
                },
                query: (partner) => {
                    return {
                        url: `/partners`,
                        method: 'PUT',
                        params: {
                            partnerName: partner.name
                        },
                        body: partner,
                        responseHandler: (response) => response.text(),
                    }
                }
            }),
            deletePartner: builder.mutation<void, {name: string, createdAt: number}>({
                invalidatesTags: (_result, _error, {name}) => {
                    return [{type: 'partner', id: 'LIST'}, {type: 'partner', id: name}];
                },
                query: ({name, createdAt}) => {
                    return {
                        url: `/partner/${name}`,
                        method: 'DELETE',
                        params: {
                            createdAt: createdAt,
                        },
                        responseHandler: (response) => response.text(),
                    }
                }
            }),
        }
    }
});

export const {
    useFetchAllPartnersQuery,
    useFetchPartnerQuery,
    useAddPartnerMutation,
    useEditPartnerMutation,
    useDeletePartnerMutation,
} = partnerApi;
export {partnerApi};