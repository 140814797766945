import {Box, SpaceBetween} from "@amzn/awsui-components-react";
import React from "react";
import {SolutionDefinition} from "../../../interfaces/solution.interface";
import {mapDetails} from "../../common/DetailsList";

interface solutionDetailsProps {
    solution: SolutionDefinition;
}
const SolutionDetails: React.FC<solutionDetailsProps> = ({ solution }) => {
    return (
        <SpaceBetween size="l">
            <div>
                <Box variant="awsui-key-label">Solution Name</Box>
                <div>{solution.title}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Solution Description</Box>
                <div>{solution.description}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Solution Area</Box>
                <div>{solution.domain}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Solution Segments</Box>
                <div>{mapDetails(solution.segments)}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Solution Industries</Box>
                <div>{mapDetails(solution.industries)}</div>
            </div>
            {solution.subIndustries &&
                <div>
                    <Box variant="awsui-key-label">Solution Sub Industries</Box>
                    {mapDetails(solution.subIndustries)}
                </div>
            }
        </SpaceBetween>
    )
}

export default SolutionDetails;