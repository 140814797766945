import React from "react";

import errorPagesIllustration from "../assets/error_pages_illustration.svg";
import './style.css'
import {Link} from "@amzn/awsui-components-react";
import {useNavigate} from "react-router-dom";

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();
    const linkClick = (event): void => {
        event.preventDefault();
        navigate('/');
    };
    return (
        <div className="center">
            <div>
                <img src={errorPagesIllustration} alt="error pages illustration"/>
            </div>
            <div className="center-text">
                <p>We could not find what you are looking for.</p>
                <Link onFollow={event => linkClick(event)}>Back to Home Page</Link>
            </div>
        </div>
    );
}

export default NotFoundPage;