import React from "react";
import {Container, FormField, Header, Multiselect, SpaceBetween} from "@amzn/awsui-components-react";
import {AWS_PROGRAMS, CUSTOMER_SEGMENT, GEO, SUB_SEGMENTS, VERTICALS, WORKLOADS} from "../../../constants/Constants";
import {mapArrayToOptions, mapOptionsToArray} from "../../../util/MapOptions";

export interface OptionalPartnerDetailsFormProps {
    segments: string[];
    setSegments: (value: string[]) => void;
    verticals: string[];
    setVerticals: (value: string[]) => void;
    workloads: string[];
    setWorkloads: (value: string[]) => void;
    programs: string[];
    setPrograms: (value: string[]) => void;
    subSegments: string[];
    setSubSegments: (value: string[]) => void;
    geos: string[];
    setGeos: (value: string[]) => void;
    segmentError: string;
    setSegmentError: (value: string) => void;
    verticalError: string;
    setVerticalError: (value: string) => void;
    workloadsError: string;
    setWorkloadsError: (value: string) => void;
    partnerProgramsError: string;
    setPartnerProgramsError: (value: string) => void;
    subSegmentsError: string;
    setSubSegmentsError: (value: string) => void;
    geosError: string;
    setGeosError: (value: string) => void;

}

const OptionalPartnerDetailsForm: React.FC<OptionalPartnerDetailsFormProps> = (props) => {

    const onGeoChange = (event) => {
        const geos = mapOptionsToArray(event.detail.selectedOptions);
        props.setGeos(geos);
        props.setGeosError("");
    }

    const onSegmentChange = (event) => {
        const segments = mapOptionsToArray(event.detail.selectedOptions);
        props.setSegments(segments);
        props.setSegmentError("");
    }

    const onVerticalsChange = (event) => {
        const verticals = mapOptionsToArray(event.detail.selectedOptions);
        props.setVerticals(verticals);
        props.setVerticalError("");
    }

    const onWorkloadsChange = (event) => {
        const workloads = mapOptionsToArray(event.detail.selectedOptions);
        props.setWorkloads(workloads);
        props.setWorkloadsError("");
    }

    const onPartnerProgramsChange = (event) => {
        const partnerPrograms = mapOptionsToArray(event.detail.selectedOptions);
        props.setPrograms(partnerPrograms);
        props.setPartnerProgramsError("");
    }

    const onSubSegmentChange = (event) => {
        const subSegments = mapOptionsToArray(event.detail.selectedOptions);
        props.setSubSegments(subSegments);
        props.setSubSegmentsError("");
    }

    const selectedGeos = mapArrayToOptions(props.geos, GEO);
    const selectedSegments = mapArrayToOptions(props.segments, CUSTOMER_SEGMENT);
    const selectedSubSegments = mapArrayToOptions(props.subSegments, SUB_SEGMENTS);
    const selectedWorkloads = mapArrayToOptions(props.workloads, WORKLOADS);
    const selectedVerticals = mapArrayToOptions(props.verticals, VERTICALS);
    const selectedPartnerPrograms = mapArrayToOptions(props.programs, AWS_PROGRAMS);


    return (
        <Container header={<Header variant="h2">Optional Details</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <FormField
                    label="Geos"
                    errorText={props.geosError}
                >
                    <Multiselect

                        placeholder="Select segments this Partner works in."
                        selectedOptions={selectedGeos}
                        options={GEO}
                        onChange={onGeoChange}
                    />
                </FormField>
                <FormField
                    label="Segments"
                    errorText={props.segmentError}
                >
                    <Multiselect

                        placeholder="Select segments this Partner works in."
                        selectedOptions={selectedSegments}
                        options={CUSTOMER_SEGMENT}
                        onChange={onSegmentChange}
                    />
                </FormField>
                <FormField
                    label="Sub Segments"
                    errorText={props.subSegmentsError}
                >
                    <Multiselect
                        placeholder="Select Startup workloads this Partner supports."
                        filteringType="auto"
                        selectedOptions={selectedSubSegments}
                        options={SUB_SEGMENTS}
                        onChange={onSubSegmentChange}
                    />
                </FormField>
                <FormField
                    label="Partner Supported Workloads"
                    errorText={props.workloadsError}
                >
                    <Multiselect
                        placeholder="Select Startup workloads this Partner supports."
                        filteringType="auto"
                        selectedOptions={selectedWorkloads}
                        options={WORKLOADS}
                        onChange={onWorkloadsChange}
                    />
                </FormField>
                <FormField
                    label="Partner Supported Verticals"
                    errorText={props.verticalError}
                >
                    <Multiselect
                        placeholder="Select Startup verticals this Partner works in."
                        selectedOptions={selectedVerticals}
                        options={VERTICALS}
                        onChange={onVerticalsChange}
                    />
                </FormField>
                <FormField
                    label="Approved Partner Programs"
                    errorText={props.partnerProgramsError}
                >
                    <Multiselect
                        placeholder="Select Partner Programs the Partner is a part of."
                        selectedOptions={selectedPartnerPrograms}
                        options={AWS_PROGRAMS}
                        onChange={onPartnerProgramsChange}
                    />
                </FormField>
            </SpaceBetween>
        </Container>
    )
}

export default OptionalPartnerDetailsForm;