import React from "react";

import "./style.css"

const Error: React.FC = () => {
    return (
        <div className="error--message">
            An error has occurred. Please try again later.
        </div>
    )
}

export default Error;