import {JumpstartFunding} from "./JumpstartFunding";
import {fundingTypeEnum, IFundingProgramProps, partnerTypeEnum} from "./FundingProgramInterface";
import {PROGRAM_DETAILS} from "../../constants/ProgramDetails";

export class SdppFunding extends JumpstartFunding {
    public programDetails = PROGRAM_DETAILS.SDPP;
    public fundingAmount: number;
    public fundingType = fundingTypeEnum.ASSESS;

    constructor(props: IFundingProgramProps) {
        super(props);
        this.fundingAmount = this.getFundingAmount();
    }

    protected getFundingAmount(): number {
        return 10000;
    }

    isApproved(): boolean {
        if (this.props.partnerType !== partnerTypeEnum.SERVICES) {
            return false;
        }

        if (!this.props.isSaasCustomer || !super.isApproved()) {
            return false;
        }

        return true;
    }
}