import React from "react";
import {Box, Spinner, SpinnerProps} from "@amzn/awsui-components-react";

const CenteredSpinner: React.FC<SpinnerProps> = (props) => {
    return (
        <div>
            <Box textAlign="center">
                <Spinner {...props} />
            </Box>
        </div>

    );
}

export default CenteredSpinner;
