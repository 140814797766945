export interface IFundingProgramDetails {
  name: string;
  key: string;
  wiki: string;
  info: string;
}

const MAP_PROGRAM_DETAILS: IFundingProgramDetails = {
  name: "Migration Acceleration Program",
	key: "map",
	wiki: "https://w.amazon.com/bin/view/AWS/Teams/Sales-BD/Playbook/Programs/Migration-Acceleration-Program/",
	info: `
    The Migration Acceleration Program (MAP) is a comprehensive and proven 
    cloud migration program founded on AWS’ extensive experience migrating hundreds 
    of enterprise customers. MAP packages best practices, expertise, tooling, and financial i
    ncentives to make cloud adoption easier. MAP is one of the most lucrative funding 
    programs available for partners and customers, and in turn will have a few extra steps 
    worthy of process to ensure customer obsession.
  `
}

const MAPLITE_PROGRAM_DETAILS: IFundingProgramDetails = {
  name: "Migration Acceleration Program Lite",
  key: "maplite",
  wiki: "https://w.amazon.com/bin/view/AWS/Teams/Sales-BD/MAP-sales-guidance/MAPLite/",
  info: `
    MAP Lite is a self-serve funding option for migrations <$500K ARR.
  `
}

const POC_PROGRAM_DETAILS: IFundingProgramDetails = {
  name: "Partner Proof of Concept (POC)",
  key: "poc",
  wiki: "https://w.amazon.com/bin/view/AWS/Teams/AWS_Partner_Organization_APO/APN_Funding/Partner_POC_Funding",
  info: `
    Proof of Concepts are small scale projects for customers that have yet to fully 
    commit to adopting AWS, but are ready to begin their cloud adoption journey. 
    For new customers, POCs can help demonstrate the feasibility and benefits of the AWS cloud. 
    For existing customers, POCs can help grow their AWS utilization by optimizing current solutions 
    and introducing new AWS products where applicable. A POC may be applicable to existing customers 
    when they want to pivot an existing or new solution to the AWS cloud. For example, they may want to 
    use Route 53 for their DNS (Domain Name System) service and so may run a POC to explore it.
  `
}

const JUMPSTART_PROGRAM_DETAILS: IFundingProgramDetails = {
  name: "Jumpstart",
  key: "jumpstart",
  wiki: "https://w.amazon.com/bin/view/AWS_Startup_Segment/JumpStart/",
  info: `
    The AWS Startup Jumpstart program supports startups that have an 
    identified need for strategic service adoption and can benefit from hands-on 
    technical resources. The program is meant to help startups that already see 
    the value in adopting a specific service(s), but have technical knowledge or 
    resource shortfalls. Through the Amazon Partner Network (APN), we offer startups 
    hands-on support  to the customer, and accelerate their implementation and knowledge transfer.
  `
}

const STARTUPMIGRATE_PROGRAM_DETAILS: IFundingProgramDetails = {
  name: "Startup Migrate",
	key: "smp",
	wiki: "https://w.amazon.com/bin/view/AWS_Startup_Segment/Migrate/",
	info: `
      The Start-up Migrate Program helps established startups migrate external workloads 
      from competitive platforms, collocation or on-premises to AWS Cloud. The program's objective 
      is to provide the startup with financial and technical support so that they can migrate to 
      AWS successfully. The program offers the following benefits: AWS Service Credits, Partner Funding, 
      and Business Support Credits.
  `
}

const DAPP_PROGRAM_DETAILS: IFundingProgramDetails = {
  name: "Data Acceleration Partner Program",
  key: "dapp",
  wiki: "https://quip-amazon.com/fBbBAMmEYnex/Data-Acceleration-Partner-Program-DAPP",
  info: `
    Your customer is looking for help designing or building a proof-of-concept for a data project,
    but does not have the technical resources.
  `
}

const SDPP_PROGRAM_DETAILS : IFundingProgramDetails = {
  name: "SaaS Discovery Partner Program",
  key: "sdpp",
  wiki: "https://quip-amazon.com/8kvOANGmTjVz/SaaS-Discovery-Partner-Program-SDPP",
  info: `
    SaaS Discovery is a new, lightweight AWS engagement process designed to enable ISVs/software companies 
    quickly engage qualified AWS SaaS Competency Partners. The format consists of a net no charge engagement 
    to provide a fixed set of deliverables to the software builders, whilst building trust and assessing 
    the SaaS Competency Partner in a low risk / low cost manner for both parties.
  `
}

const START_BOOST_PROGRAM_DETAILS: IFundingProgramDetails = {
    name: "Start Boost",
    key: "startboost",
    wiki: "https://w.amazon.com/bin/view/ANZStartupSegment/Partners/Startboost/",
    info: `
    Startboost Program Objective 
    Many early-stage startups lack development resources; and struggle to bring their ideas to value leveraging the cloud. 
    To assist, AWS launched an initiative to help these startups with cost-effective, repeatable offerings, 
    delivered by members of the Amazon Partner Network (APN).
    
    The Startboost program is managed under the broader Jumpstart Program framework.   

    Startboost is a series of foundational fixed and repeatable offerings that allow early-stage start-ups to 
    accelerate their development process with AWS. Ensuring they start their cloud journey following the best practices 
    for security, cost, operational excellence, performance efficiency, and reliability. Because of this, 
    AWS partners can apply significant reductions to their prices. Besides, AWS is funding 50% of the costs;
     allowing customers the freedom to build their applications correctly from the start of their business journey.
  `
}

const BASE_CAMP_PROGRAM_DETAILS: IFundingProgramDetails = {
  name: "Base Camp",
  key: "basecamp",
  wiki: "https://w.amazon.com/bin/view/Startup_Partner_Resources/ConsultingPartnerSolutions/",
  info: `
    BaseCamp is an exclusive Startup funding program offered through Jumpstart. The purpose of Basecamp is to: 
    1/ introduce T0 VC backed customers to our top four Startup partners, 2/ educate customers on the benefits of
   operating and scaling on AWS quickly, and 3/ allow customers to focus on their core business priorities. 
   The targeted list of 102 customers are early-stage startups that face competing priorities. 
   The majority of these startups are focused on finding their minimum viable product, customer acquisition, 
   and building a cost-effective technological framework.
   `
}

const SMAP_PROGRMAM_DETIALS: IFundingProgramDetails = {
  name: "Startup Migrate Assess Phase",
  key: "smap",
  wiki: "https://w.amazon.com/bin/view/AWS_Startup_Segment/Migrate/",
  info: `
    Startup Migrate Assess Phase (SMAP) is used to create a compelling event for customers to pursue their 
    migration project with AWS. It’s the first phase of the customer migration journey and doesn’t require an upfront 
    migration commitment. The activities funded through Assess phase may include a discovery of existing applications, 
    analysis of migrating versus staying on the current environment, and/or developing a business case to create 
    interest in migrating to AWS. Overall, the expected outcome from this phase is to create a captivating event for 
    startups to move forward a deal that is stalled or delayed or to create new interest to migrate to AWS cloud.
  `
}

export const PROGRAM_DETAILS = {
  MAP_LITE: MAPLITE_PROGRAM_DETAILS,
  MAP: MAP_PROGRAM_DETAILS,
  POC: POC_PROGRAM_DETAILS,
  JUMPSTART: JUMPSTART_PROGRAM_DETAILS,
  SMP: STARTUPMIGRATE_PROGRAM_DETAILS,
  DAPP: DAPP_PROGRAM_DETAILS,
  SDPP: SDPP_PROGRAM_DETAILS,
  START_BOOST: START_BOOST_PROGRAM_DETAILS,
  BASE_CAMP: BASE_CAMP_PROGRAM_DETAILS,
  SMAP: SMAP_PROGRMAM_DETIALS,
}
