import {
    fundingTypeEnum,
    IFundingProgram,
    IFundingProgramProps, partnerTypeEnum,
    industryEnum,
    subSegmentEnum
} from "./FundingProgramInterface";
import {PROGRAM_DETAILS} from "../../constants/ProgramDetails";

export class StartupMigrateFunding implements IFundingProgram {
    private props: IFundingProgramProps;
    public programDetails = PROGRAM_DETAILS.SMP;
    public fundingAmount: number;
    public fundingType = fundingTypeEnum.IMPLEMENT

    constructor(props: IFundingProgramProps) {
        this.props = props;
        this.fundingAmount = this.getFundingAmount();
    }

    private getFundingAmount(): number {
        let fundingAmount = 0;

        if (this.props.subSegment === subSegmentEnum.PRIORITY){
            fundingAmount = this.props.mrr * 8;
        }
        if (this.props.subSegment === subSegmentEnum.CORE){
            fundingAmount = this.props.mrr * 5;
        }

        return fundingAmount;
    }

    isApproved(): boolean {
        if (this.props.partnerType !== partnerTypeEnum.SERVICES) {
            return false;
        }

        if (this.props.segment !== industryEnum.SUP) {
            return false;
        }

        if (!this.props.migration) {
            return false;
        }

        if (this.props.mrr * 12 >= 500000) {
            return false;
        }

        if (this.props.mrr < 2000) {
            return false;
        }

        if (this.props.mrr < 5000 && this.props.subSegment === subSegmentEnum.PRIORITY) {
            return false;
        }

        return true;
    }
}