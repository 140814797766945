import React from "react";
import {Container, FormField, Header, Input, Select, SpaceBetween} from "@amzn/awsui-components-react";
import {OptionDefinition} from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import {useFetchAllPartnersQuery} from "../../../store";

export interface SolutionPartnerFormProps {
    partner: string;
    setPartner: (partner: string) => void;
    partnerError: string;
    setPartnerError: (partnerError: string) => void;
    isvPsmName: string;
    setIsvPsmName: (isvPsmName: string) => void;
    isvPsmNameError: string;
    setIsvPsmNameError: (isvPsmNameError: string) => void;
    isvPsmEmail: string;
    setIsvPsmEmail: (isvPsmEmail: string) => void;
    isvPsmEmailError: string;
    setIsvPsmEmailError: (isvPsmEmailError: string) => void;
    partnerContactName: string;
    setPartnerContactName: (externalPartnerName: string) => void;
    partnerContactEmail: string;
    setPartnerContactEmail: (externalPartnerEmail: string) => void;
    partnerContactNameError: string;
    setPartnerContactNameError: (externalPartnerNameError: string) => void;
    partnerContactEmailError: string;
    setPartnerContactEmailError: (externalPartnerEmailError: string) => void;
}

const SolutionPartnerForm: React.FC<SolutionPartnerFormProps> = (props) => {

    const { data, isFetching } = useFetchAllPartnersQuery();

    let partnerOptions:  OptionDefinition[] = [];
    if (data) {
        data.forEach(
            (partner) => {
                partnerOptions.push({label: partner.name, value: partner.name});
            }
        )
    }

    const selectedPartnerOption = partnerOptions.find((partner) => {
        return partner.label === props.partner;
    }) as OptionDefinition;

    const onPartnerChange = (event): void => {
        const partner = event.detail.selectedOption.label;
        props.setPartner(partner);
        props.setPartnerError("");
    }

    const isvPsmNameChange = (event): void => {
        props.setIsvPsmName(event.detail.value);
        props.setIsvPsmNameError("");
    }

    const isvPsmEmailChange = (event): void => {
        props.setIsvPsmEmail(event.detail.value);
        props.setIsvPsmEmailError("");
    }

    const partnerContactNameChange = (event): void => {
        props.setPartnerContactName(event.detail.value);
        props.setPartnerContactNameError("");
    }

    const partnerContactEmailChange = (event): void => {
        props.setPartnerContactEmail(event.detail.value);
        props.setPartnerContactEmailError("");
    }

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    description="Enter Partner information related to the solution."
                >
                    Partner Information
                </Header>
            }
        >
            <SpaceBetween direction="vertical" size="l">
                <FormField
                    label="Partner Name"
                    errorText={props.partnerError}
                >
                    <Select
                        selectedOption={selectedPartnerOption}
                        onChange={onPartnerChange}
                        options={partnerOptions}
                        selectedAriaLabel="Selected"
                        statusType={isFetching ? "loading" : "finished"}
                    />
                </FormField>
                <FormField
                    label="ISV Co-Sell PSM Name"
                    errorText={props.isvPsmNameError}
                >
                    <Input
                        value={props.isvPsmName}
                        onChange={isvPsmNameChange}
                    />
                </FormField>
                <FormField
                    label="ISV Co-Sell PSM Email"
                    errorText={props.isvPsmEmailError}
                >
                    <Input
                        value={props.isvPsmEmail}
                        onChange={isvPsmEmailChange}
                    />
                </FormField>
                <FormField
                    label="External Partner Contact Name"
                    errorText={props.partnerContactNameError}
                >
                    <Input
                        value={props.partnerContactName}
                        onChange={partnerContactNameChange}
                    />
                </FormField>
                <FormField
                    label="External Partner Contact Email"
                    errorText={props.partnerContactEmailError}
                >
                    <Input
                        value={props.partnerContactEmail}
                        onChange={partnerContactEmailChange}
                    />
                </FormField>
            </SpaceBetween>
        </Container>
    );
}

export default SolutionPartnerForm;