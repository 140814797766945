import {fundingTypeEnum, IFundingProgramProps} from "./FundingProgramInterface";
import {MapLiteFunding} from "./MapLiteFunding";

export class MapLiteMobilizeFunding extends MapLiteFunding {
    public fundingAmount: number;
    public fundingType = fundingTypeEnum.MOBILIZE;

    constructor(props: IFundingProgramProps) {
        super(props);
        this.fundingAmount = this.getFundingAmount();
    }

    protected getFundingAmount(): number {
        let mobilize = this.props.sowCost / 2;
        let maxMobilize = this.props.mrr * 12 * 0.2;

        if (maxMobilize < mobilize) {
            mobilize = maxMobilize;
        }

        return mobilize;
    }
}