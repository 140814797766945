import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AppLayout } from '@amzn/awsui-components-react';

import {
    NotFoundPage,
    PartnerDetailPage,
    PartnerEditPage,
    PartnersAddPage,
    PartnersPage,
    SolutionAddPage,
    SolutionEditPage,
    SolutionMainPage,
    SolutionsPage,
} from '../../pages';
import HubNavigation from '../hubnavigation';
import FundingResults from '../funding/FundingResults';
import FundingPage from '../funding/FundingPage';
import ErrorBoundary from '../errorboundry/ErrorBoundary';
import AccountsPage from '../../pages/AccountsPage';

const Router: React.FC = () => {
    return (
        <ErrorBoundary>
            <Routes>
                <Route path="/" element={<SolutionsPage />} />
                <Route path="/solution/:id" element={<SolutionMainPage />} />
                <Route
                    path="/solution/edit/:id"
                    element={<SolutionEditPage />}
                />
                <Route path="/solution/upload" element={<SolutionAddPage />} />
                <Route path="/partners" element={<PartnersPage />} />
                <Route path="/partners/add" element={<PartnersAddPage />} />
                <Route path="/partners/:id" element={<PartnerDetailPage />} />
                <Route
                    path="/partners/edit/:id"
                    element={<PartnerEditPage />}
                />
                <Route path="/funding" element={<FundingPage />} />
                <Route
                    path="/funding/results"
                    element={
                        <AppLayout
                            contentType="default"
                            headerSelector="#h"
                            content={<FundingResults />}
                            navigation={<HubNavigation activeHref="/funding" />}
                        />
                    }
                />
                <Route path="/accounts" element={<AccountsPage />} />
                <Route
                    path="/404"
                    element={
                        <AppLayout
                            contentType="default"
                            headerSelector="#h"
                            content={<NotFoundPage />}
                            toolsHide={true}
                            navigationHide={true}
                        />
                    }
                />
            </Routes>
        </ErrorBoundary>
    );
};

export default Router;
