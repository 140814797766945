import {
    fundingTypeEnum,
    IFundingProgram,
    IFundingProgramProps,
    partnerTypeEnum,
    industryEnum
} from "./FundingProgramInterface";
import {PROGRAM_DETAILS} from "../../constants/ProgramDetails";

export class MapFunding implements IFundingProgram {
    protected props: IFundingProgramProps;
    public programDetails = PROGRAM_DETAILS.MAP;
    public fundingAmount: number;
    public fundingType = fundingTypeEnum.IMPLEMENT;

    constructor(props: IFundingProgramProps) {
        this.props = props;
        this.fundingAmount = this.getFundingAmount();
    }

    protected getFundingAmount(): number {
        return this.props.mrr * 12 * 0.25;
    }

    isApproved(): boolean {
        if (this.props.partnerType !== partnerTypeEnum.SERVICES) {
            return false;
        }

        if (!this.props.migration) {
            return false;
        }

        if (this.props.mrr * 12 < 250000) {
            return false;
        }

        if (this.props.segment === industryEnum.SUP && this.props.mrr * 12 < 500000) {
            return false;
        }

        return true;
    }
}