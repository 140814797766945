import React from 'react';
import {
    Container,
    FormField,
    Header,
    Input,
    Multiselect,
    SpaceBetween,
    Tiles,
} from '@amzn/awsui-components-react';
import { PARTNER_TIERS_SOFTWARE, YES_NO } from '../../../constants/Constants';
import { mapArrayToOptions, mapOptionsToArray } from '../../../util/MapOptions';

export interface PartnerDetailsFormProps {
    name: string;
    setName: (name: string) => void;
    tier: string[];
    setTier: (tier: string[]) => void;
    discoveryLink: string;
    setDiscoveryLink: (link: string) => void;
    pdmName: string;
    setPdmName: (name: string) => void;
    pdmEmail: string;
    setPdmEmail: (email: string) => void;
    gtmPdmName: string;
    setGtmPdmName: (gtmPdm: string) => void;
    gtmPdmEmail: string;
    setGtmPdmEmail: (gtmPdmEmail: string) => void;
    sca: boolean;
    setSca: (sca: boolean) => void;
    nameError: string;
    setNameError: (nameError: string) => void;
    tierError: string;
    setTierError: (tierError: string) => void;
    linkError: string;
    setLinkError: (linkError: string) => void;
    pdmNameError: string;
    setPdmNameError: (nameError: string) => void;
    pdmEmailError: string;
    setPdmEmailError: (emailError: string) => void;
    gtmPdmNameError: string;
    setGtmPdmNameError: (gtmPdmError: string) => void;
    gtmPdmEmailError: string;
    setGtmPdmEmailError: (gtmPdmEmailError: string) => void;
    scaError: string;
    setScaError: (scaError: string) => void;
    editMode: boolean;
}

const PartnerDetailsForm: React.FC<PartnerDetailsFormProps> = (props) => {
    const onNameChange = (event): void => {
        props.setName(event.detail.value);
        props.setNameError('');
    };
    const onTierChange = (event) => {
        const tiers = mapOptionsToArray(event.detail.selectedOptions);
        props.setTier(tiers);
        props.setTierError('');
    };

    const onLinkChange = (event) => {
        props.setDiscoveryLink(event.detail.value);
        props.setLinkError('');
    };

    const pdmNameChange = (event) => {
        props.setPdmName(event.detail.value);
        props.setPdmNameError('');
    };

    const pdmEmailChange = (event) => {
        props.setPdmEmail(event.detail.value);
        props.setPdmEmailError('');
    };

    const gtmPdmChange = (event) => {
        props.setGtmPdmName(event.detail.value);
        props.setGtmPdmNameError('');
    };

    const gtmPdmEmailChange = (event) => {
        props.setGtmPdmEmail(event.detail.value);
        props.setGtmPdmEmailError('');
    };

    const onScaChange = (event) => {
        if (event.detail.value === 'true') {
            props.setSca(true);
        } else {
            props.setSca(false);
        }
        props.setScaError('');
    };

    const tiers = mapArrayToOptions(props.tier, PARTNER_TIERS_SOFTWARE);

    return (
        <Container header={<Header variant="h2">Partner Details</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <FormField label="Partner Name" errorText={props.nameError}>
                    <Input
                        value={props.name}
                        onChange={onNameChange}
                        disabled={props.editMode}
                    />
                </FormField>
                <FormField label="Partner Tiers" errorText={props.tierError}>
                    <Multiselect
                        placeholder="Select Tiers this Partner qualifies for."
                        selectedOptions={tiers}
                        options={PARTNER_TIERS_SOFTWARE}
                        onChange={onTierChange}
                    />
                </FormField>
                <FormField label="Partner has SCA" errorText={props.scaError}>
                    <Tiles
                        onChange={onScaChange}
                        value={props.sca ? 'true' : 'false'}
                        items={YES_NO}
                    />
                </FormField>
                <FormField
                    label="Partner Discovery Portal Link"
                    errorText={props.linkError}
                >
                    <Input
                        value={props.discoveryLink}
                        onChange={onLinkChange}
                    />
                </FormField>
                <FormField
                    label="Partner Development Manager Name"
                    errorText={props.pdmNameError}
                >
                    <Input value={props.pdmName} onChange={pdmNameChange} />
                </FormField>
                <FormField
                    label="Partner Development Manager Email"
                    errorText={props.pdmEmailError}
                >
                    <Input value={props.pdmEmail} onChange={pdmEmailChange} />
                </FormField>
                <FormField
                    label="Go To Market PDM"
                    errorText={props.gtmPdmNameError}
                >
                    <Input value={props.gtmPdmName} onChange={gtmPdmChange} />
                </FormField>
                <FormField
                    label="Go To Market PDM Email"
                    errorText={props.gtmPdmEmailError}
                >
                    <Input
                        value={props.gtmPdmEmail}
                        onChange={gtmPdmEmailChange}
                    />
                </FormField>
            </SpaceBetween>
        </Container>
    );
};

export default PartnerDetailsForm;
