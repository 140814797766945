import React from "react";
import {Box, ColumnLayout, Link, SpaceBetween} from "@amzn/awsui-components-react";
import {PartnerDefinition} from "../../../interfaces/partner.interface";
import {MailTo} from "../../common/MailTo";
import {mapDetails} from "../../common/DetailsList";

interface PartnerDetailsProps {
    partner: PartnerDefinition;
}

const PartnerCommonDetails: React.FC<PartnerDetailsProps> = ({partner}) => {
    return (
        <ColumnLayout columns={2} variant="text-grid">
            <SpaceBetween size="l" direction="vertical">
                <div>
                    <Box variant="awsui-key-label">Partner Name</Box>
                    <div>{partner.name}</div>
                </div>
                <div>
                    <Box variant="awsui-key-label">Partner Tiers</Box>
                    <div>{mapDetails(partner.tier)}</div>
                </div>
                {(partner.pdmName && partner.pdmEmail) &&
                    <div>
                        <Box variant="awsui-key-label">Partner Development Manager</Box>
                        <div><MailTo label={partner.pdmName} mailto={partner.pdmEmail}/></div>
                    </div>
                }
            </SpaceBetween>
            <SpaceBetween size="l" direction="vertical">
                <div>
                    <Box variant="awsui-key-label">Discovery Portal Link</Box>
                    <div><Link href={partner.discoveryLink} target="_bank">{partner.name}</Link></div>
                </div>
            </SpaceBetween>

        </ColumnLayout>
    )
}

export default PartnerCommonDetails;