import React, {useEffect, useState} from 'react';

import TopNavBar from "./components/topnavbar";
import Router from "./components/router";
import CenteredSpinner from "./components/common/CenteredSpinner";
import {authenticateUser} from "./util/AuthService";

import "./App.css";
const App = () => {

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState("");

    const onAuthenticationSuccess = (authenticatedUser) => {
        setIsUserAuthenticated(true);
        setUserName(authenticatedUser.attributes.preferred_username);
        setEmail(authenticatedUser.attributes.email);
    };

    useEffect( () => {
        const originalUrl = window.location.href;
        sessionStorage.setItem('originalUrl',originalUrl);
        const checkSignedIn = async () => {
            const authenticatedUser = await authenticateUser();
            if (authenticatedUser) {
                onAuthenticationSuccess(authenticatedUser);
            }
        };
        checkSignedIn();

    }, []);

    if (isUserAuthenticated) {
        return (
            <div>
                <TopNavBar
                    utilities={[
                        {
                            type: "menu-dropdown",
                            text: userName,
                            description: email,
                            iconName: "user-profile",
                            items: []
                        }
                    ]}
                />
                <Router />
            </div>
        );
    } else {
        return <CenteredSpinner size="large" />
    }
};

export default App;