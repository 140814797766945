import React from "react";
import {Box} from "@amzn/awsui-components-react";

export const mapDetails = (details: string[]) => {
    let component;
    if (details) {
        component = details.map((value, index) => {
            return <Box padding={{top: "xxs"}} key={index}>{value}</Box>
        });
    } else {
        component = <div></div>
    }

    return component;
}