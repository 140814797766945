import {
    fundingTypeEnum,
    IFundingProgram,
    IFundingProgramProps,
    partnerTypeEnum,
    industryEnum
} from "./FundingProgramInterface";
import {PROGRAM_DETAILS} from "../../constants/ProgramDetails";

export class BaseCampFunding implements IFundingProgram {
    private props: IFundingProgramProps;
    public programDetails = PROGRAM_DETAILS.BASE_CAMP;
    public fundingAmount: number;
    public fundingType = fundingTypeEnum.IMPLEMENT;

    constructor(props: IFundingProgramProps) {
        this.props = props;
        this.fundingAmount = this.getFundingAmount();
    }

    private getFundingAmount(): number {
        const fundingAmount = this.props.sowCost * 0.5;
        if (fundingAmount > 50000) {
            return 50000;
        }
        return fundingAmount;
    }

    isApproved(): boolean {
        return (this.props.segment === industryEnum.SUP &&
            this.props.isSeedStage &&
            this.props.t0VcBacked &&
            this.props.partnerType === partnerTypeEnum.SERVICES
        )
    }

}