import React from 'react';
import { Box, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { SolutionDefinition } from '../../../interfaces/solution.interface';
import BoxPaddedBottom from '../../common/BoxPaddedBottom';
import { mapDetails } from '../../common/DetailsList';

interface SolutionExtraInfoProps {
    solution: SolutionDefinition;
}

const SolutionExtraInfo: React.FC<SolutionExtraInfoProps> = ({ solution }) => {
    let boostUrlLinks: React.JSX.Element[];

    if (solution.boostUrls) {
        boostUrlLinks = solution.boostUrls.map((boostUrl) => {
            return (
                <div>
                    <Link href={boostUrl} target="_blank">
                        {boostUrl}
                    </Link>
                </div>
            );
        });
    } else {
        boostUrlLinks = [];
    }

    console.log(solution.boostUrls);

    return (
        <SpaceBetween size="l">
            {solution.tags.length > 0 && (
                <div>
                    <BoxPaddedBottom variant="awsui-key-label">
                        Tags
                    </BoxPaddedBottom>
                    {mapDetails(solution.tags)}
                </div>
            )}
            {solution.hasMarketplace && (
                <div>
                    <Box variant="awsui-key-label">Market Place Link</Box>
                    <Link href={solution.marketplaceUrl} target="_blank">
                        {solution.marketplaceUrl}
                    </Link>
                </div>
            )}
            {solution.hasBoost && (
                <div>
                    <Box variant="awsui-key-label">Boost Campaigns</Box>
                    {boostUrlLinks}
                </div>
            )}
        </SpaceBetween>
    );
};

export default SolutionExtraInfo;
