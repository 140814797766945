import {fundingTypeEnum, IFundingProgramProps} from "./FundingProgramInterface";
import {MapFunding} from "./MapFunding";

export class MapAssessFunding extends MapFunding {
    public fundingAmount: number;
    public fundingType = fundingTypeEnum.ASSESS;

    constructor(props: IFundingProgramProps) {
        super(props);
        this.fundingAmount = this.getFundingAmount();
    }

    protected getFundingAmount(): number {
        return 60000;
    }
}