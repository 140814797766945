import React, {useEffect, useState} from "react";

import {AppLayout} from "@amzn/awsui-components-react";
import HubNavigation from "../components/hubnavigation";
import { SolutionForm } from "../components/solutions/solutionform";
import Notification from "../components/common/Notification";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {notificationTypeEnum} from "../constants/Constants";

const SolutionAddPage: React.FC = () => {

    const notificationType = useSelector((state: RootState) => state.notification.notificationType);
    const content = useSelector((state: RootState) => state.notification.content);
    const eventType = useSelector((state: RootState) => state.notification.eventType);

    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        if (notificationType === notificationTypeEnum.ADDSOLUTION) {
            setShowNotification(true);
        }
    }, [notificationType, content, eventType])

    return (
        <div>
            <AppLayout
                contentType="form"
                headerSelector="#h"
                content={<SolutionForm editMode={false} />}
                navigation={<HubNavigation />}
                notifications={<Notification showNotification={showNotification} type={eventType} message={content}/>}
            />
        </div>
    )
}

export default SolutionAddPage;