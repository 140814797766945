import React from "react";
import {
    Header,
    Link,
    Table
} from "@amzn/awsui-components-react";

import {IFundingProgram} from "../../util/funding/FundingProgramInterface";
import {useCollection} from "@amzn/awsui-collection-hooks";


function createLabelFunction(columnName) {
    return ({sorted, descending}) => {
        const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
        return `${columnName}, ${sortState}.`;
    };
}
export interface FundingProgramViewProps {
    fundingPrograms: IFundingProgram[];
    fundingType: string;
}

const FundingProgramView: React.FC<FundingProgramViewProps> = (props) => {

    const columns = [
        {
            id: 'name',
            header: "Funding Program Name",
            cell: item => <Link href={item.programDetails.wiki} target="_blank">{item.programDetails.name}</Link>,
            ariaLabel: createLabelFunction('name'),
        },
        {
            id: 'description',
            header: "Description",
            cell: item => item.programDetails.info,
        },
        {
            id: 'amount',
            header: "Funding Amount",
            cell: item => `\$${Math.round(item.fundingAmount)}`,
            sortingField: 'fundingAmount',
        }
    ]

    const { items, collectionProps} = useCollection(
        props.fundingPrograms,
        {
            sorting: {defaultState: { sortingColumn: columns[2],  isDescending: true }},
            selection: {}
        });


    return (
        <div>
                <Table
                    {...collectionProps}
                    header={<TableHeader fundingType={props.fundingType}/>}
                    columnDefinitions={columns}
                    items={items}
                    variant="container"
                    wrapLines={true}
                />

        </div>
    );
}

interface FundingHeaderProps {
    fundingType: string;
}


const TableHeader: React.FC<FundingHeaderProps> = (props) => {
    return (
        <div>
            <Header
                variant="awsui-h1-sticky"
            >
                {props.fundingType}
            </Header>
        </div>
    )
}
export default FundingProgramView;
