import {backendUrl} from "../AuthenticatedRoute/Amplify";
import {getIdToken} from "./AuthService";

export const getSolutionPdf = async (id: number): Promise<string> => {
    let data = "";

    const jwt = await getIdToken();
    const encodedId = encodeURIComponent(id);
    const requestOptions = {
        method: 'GET',
        headers: {'Authorization': jwt}
    }
    try {
        const response = await fetch(`${backendUrl}/solution/file?id=${encodedId}`, requestOptions);
        data = await response.json() as string;
    } catch (err: any) {
        console.error(err.message);
    }

    return data;
}

export const storePdf = async (url: string, file: File): Promise<void> => {
    try {
        const response = await fetch(url, {
            method: "PUT",
            body: file
        });
        if (!response.ok) {
            throw new Error('Response was not ok.');
        }
    } catch (err: any) {
        console.error(`Error while uploading file. Error: ${err.message}`);
    }
}
