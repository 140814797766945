import React, {Component, ReactNode} from "react";
import {rumConfig} from "../../AuthenticatedRoute/Amplify";
import {analytics} from "./analytics";
import {AppLayout} from "@amzn/awsui-components-react";
import ErrorComponent from "../error/ErrorComponent";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {hasError: false};

    static getDerivedStateFromError(error: Error): State {
        console.log(error);
        return {hasError: true};
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.log(`Error: ${error} \n ErrorInfo: ${errorInfo}`);
        console.log("Hit error boundary");
        if (rumConfig.RUM_APP_ID && rumConfig.RUM_ROLE_ARN && rumConfig.RUM_IDPOOL_ID) {
            analytics.recordError(error);
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <AppLayout
                    contentType="form"
                    headerSelector="#h"
                    content={<ErrorComponent />}
                />
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary;