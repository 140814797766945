import React, {useId, useState} from "react";
import {FlashbarProps} from "@amzn/awsui-components-react";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";
import {resetNotification} from "../../store";
import {useDispatch} from "react-redux";

export interface NotificationProps {
    showNotification: boolean;
    type: FlashbarProps.Type | undefined;
    message: React.ReactNode;
}
const useNotifications= (showNotification= false, type: FlashbarProps.Type, notificationMessage: React.ReactNode) => {
    const dispatch = useDispatch();
    const successId = useId();
    const [successDismissed, dismissSuccess] = useState(false);

    const notifications: Array<FlashbarProps.MessageDefinition> = [];

    if (showNotification && !successDismissed) {
        notifications.push({
            id: successId,
            content: notificationMessage,
            type: type,
            dismissLabel: "Dismiss",
            onDismiss: () => {
                dismissSuccess(true);
                dispatch(resetNotification());
            },
            dismissible: true
        });
    }
    return notifications;
}

const Notifications: React.FC<NotificationProps> = ({showNotification, type, message}) => {
    if (type) {
        const notifications = useNotifications(showNotification, type, message);
        return <Flashbar items={notifications} />;
    } else {
        return <></>;
    }

}

export default Notifications;