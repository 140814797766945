import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    Button,
    Container,
    Form,
    FormField,
    Header,
    Input,
    Link,
    Multiselect,
    RadioGroup,
    SpaceBetween,
    Tiles
} from "@amzn/awsui-components-react";
import {
    CUSTOMER_INDUSTRY,
    GEO,
    JUMPSTART_SERVICES_TRACKS,
    PARTNER_TYPES,
    TERRITORY, SUB_SEGMENTS,
    WORKLOAD_TYPE,
    YES_NO
} from "../../constants/Constants";
import {getFundingPrograms} from "../../util/funding/FundingPrograms";
import {
    geoEnum,
    IFundingProgramProps,
    partnerTypeEnum,
    industryEnum,
    subSegmentEnum,
    territoryEnum
} from "../../util/funding/FundingProgramInterface";


interface FundingFormProps {
    replaceToolsContent(index: string): void;
}
const FundingForm: React.FC<FundingFormProps> = (props: FundingFormProps) => {

    let navigate = useNavigate();

    const [geo, setGeo] = useState("NAMER");
    const [segment, setSegment] = useState("ENT");
    const [territory, setTerritory] = useState<string>("GRN");
    const [subSegment, setSubSegment] = useState<string>("CORE");
    const [isSeedStage, setIsSeedStage] = useState(false);
    const [isT0VcBacked, setIsT0VcBacked] = useState(false);
    const [isSaaSCustomer, setIsSaaSCustomer] = useState(false);
    const [partnerType, setPartnerType] = useState("SERVICES");
    const [migration, setMigration] = useState(false);
    const [sowCost, setSowCost] = useState(0);
    const [mrr, setMrr] = useState(0);
    const [servicesList, setServicesList] = useState<any>([]);

    const [geoError, setGeoError] = useState("");
    const [segmentError, setSegmentError] = useState("");
    const [territoryError, setTerritoryError] = useState("");
    const [subSegmentError, setSubSegmentError] = useState("");
    const [isSeedStageError, setIsSeedStageError] = useState("");
    const [isT0VcBackedError, setIsT0VcBackedError] = useState("");
    const [isSaaSCustomerError, setIsSaaSCustomerError] = useState("");
    const [partnerTypeError, setPartnerTypeError] = useState("");
    const [migrationError, setMigrationError] = useState("");
    const [sowCostError, setSowCostError] = useState("");
    const [mrrError, setMrrError] = useState("");
    const [servicesListError, setServicesListError] = useState("");

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const correctInfo = checkFields();
        if (correctInfo) {

           const formState: IFundingProgramProps = {
               geo: geoEnum[geo],
               segment: industryEnum[segment],
               subSegment: subSegmentEnum[subSegment],
               territory: territoryEnum[territory],
               migration: migration,
               sowCost: sowCost,
               mrr: mrr,
               partnerType: partnerTypeEnum[partnerType],
               serviceTypes: servicesList,
               isSaasCustomer: isSaaSCustomer,
               isSeedStage: isSeedStage,
               t0VcBacked: isT0VcBacked,
           }

           const fundingPrograms = getFundingPrograms(formState);
           navigate('/funding/results', { state: fundingPrograms });
        }
    }
    const checkFields = (): boolean => {
        let formSubmit = true;

        if (!geo) {
            setGeoError("Please select a customer geo.");
            formSubmit = false;
        }
        if (!segment) {
            setSegmentError("Please select a customer segment.");
            formSubmit = false;
        }
        if (!territory){
            setTerritoryError("Please select if customer is a greenfield or engaged customer.");
            formSubmit = false;
        }
        if (!sowCost || sowCost === 0) {
            setSowCostError("This field can't be empty or 0.");
            formSubmit = false;
        }
        if (!subSegment){
            setSubSegmentError("Please select if customer is in the priority or core sub segment.");
            formSubmit = false;
        }
        if (!mrr || mrr === 0) {
            setMrrError("This field can't be empty or 0.");
            formSubmit = false;
        }
        if (migration === null) {
            setMigrationError("Please set the type of workload.");
            formSubmit = false;
        }
        if (!partnerType){
            setPartnerTypeError("Please select a partner type.");
            formSubmit = false;
        }
        if (segment === industryEnum.SUP){
            formSubmit = checkSupFields(formSubmit);
        }

        return formSubmit;
    };

    const checkSupFields = (formSubmit: boolean): boolean => {

        if (isSeedStage === null){
            setIsSeedStageError("Please select if customer is a seed stage customer.");
            formSubmit = false;
        }
        if (isT0VcBacked === null){
            setIsT0VcBackedError("Please select if customer is a T0 VC customer.");
            formSubmit = false;
        }
        if (isSaaSCustomer === null){
            setIsSaaSCustomerError("Please select if customer is a SaaS customer.");
            formSubmit = false;
        }

        return formSubmit;
    }

    return (
        <div>
            <form onSubmit={onSubmit}>
                <Form
                    header={<Header variant="h1">Partner Funding Selector</Header>}
                    actions={
                        // located at the bottom of the form
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link">Cancel</Button>
                            <Button variant="primary">
                                Submit
                            </Button>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                    <Container
                        header={<Header variant="h2">Customer information</Header>}
                    >
                        <SpaceBetween size="l">
                            <FormField
                                label="Customer Geo"
                                errorText={geoError}
                                info={
                                    <Link
                                        variant="info"
                                        onFollow={() => props.replaceToolsContent("geoDescription")}
                                    >
                                        Info

                                    </Link>
                                }
                                stretch={true}
                            >
                                <Tiles
                                    onChange={e => setGeo(e.detail.value)}
                                    value={geo}
                                    items={GEO}
                                />
                            </FormField>
                            <FormField
                                label="Customer Segment"
                                errorText={segmentError}
                                info={
                                    <Link
                                        variant="info"
                                        onFollow={() => props.replaceToolsContent("segmentDescription")}
                                    >
                                        Info

                                    </Link>
                                }
                                stretch={true}
                            >
                                <Tiles
                                    onChange={e => setSegment(e.detail.value)}
                                    value={segment}
                                    items={CUSTOMER_INDUSTRY}
                                />
                            </FormField>
                            <FormField
                                label="Sub Segment"
                                errorText={subSegmentError}
                                info={
                                    <Link
                                        variant="info"
                                        onFollow={() => props.replaceToolsContent("subSegmentDescription")}
                                    >
                                        Info
                                    </Link>
                                }
                                stretch={true}
                            >
                                <RadioGroup
                                    onChange={e => {
                                        setSubSegment(e.detail.value);
                                        setSubSegmentError("");
                                    }
                                    }
                                    items={TERRITORY}
                                    value={subSegment}
                                />
                            </FormField>
                            <FormField
                                label="Customer Territory"
                                errorText={territoryError}
                                info={
                                    <Link
                                        variant="info"
                                        onFollow={() => props.replaceToolsContent("customerTypeDescription")}
                                    >
                                        Info
                                    </Link>
                                }
                                stretch={true}
                            >
                                <RadioGroup
                                    onChange={e => {
                                        setTerritory(e.detail.value);
                                        setTerritoryError("");
                                    }
                                    }
                                    items={SUB_SEGMENTS}
                                    value={territory}
                                />
                            </FormField>
                            {(segment === industryEnum.SUP) &&
                                <FormField
                                    label="Seed Stage Startup"
                                    errorText={isSeedStageError}
                                    info={
                                        <Link
                                            variant="info"
                                            onFollow={() => props.replaceToolsContent("seedStageDescription")}
                                        >
                                            Info
                                        </Link>
                                    }
                                    stretch={true}
                                >
                                    <RadioGroup
                                        onChange={e => {
                                            setIsSeedStage(e.detail.value === "true");
                                            setIsSeedStageError("");

                                        }
                                        }
                                        items={YES_NO}
                                        value={(isSeedStage) ? "true" : "false"}
                                    />
                                </FormField>
                                }
                            {(segment === industryEnum.SUP) &&
                                <FormField
                                    label="VC Tier 0 Backed"
                                    errorText={isT0VcBackedError}
                                    info={
                                        <Link
                                            variant="info"
                                            onFollow={() => props.replaceToolsContent("vcTier0Description")}
                                        >
                                            Info
                                        </Link>
                                    }
                                    stretch={true}
                                >
                                    <RadioGroup
                                        onChange={e => {
                                            setIsT0VcBacked(e.detail.value === "true");
                                            setIsT0VcBackedError("");
                                        }
                                        }
                                        items={YES_NO}
                                        value={(isT0VcBacked) ? "true" : "false"}
                                    />
                                </FormField>
                            }
                            {(segment === industryEnum.SUP) &&
                                <FormField
                                    label="SaaS Customer"
                                    errorText={isSaaSCustomerError}
                                    info={
                                        <Link
                                            variant="info"
                                            onFollow={() => props.replaceToolsContent("saasCustomerDescription")}
                                        >
                                            Info
                                        </Link>
                                    }
                                    stretch={true}
                                >
                                    <RadioGroup
                                        onChange={e => {
                                            setIsSaaSCustomer(e.detail.value === "true");
                                            setIsSaaSCustomerError("");
                                        }
                                        }
                                        items={YES_NO}
                                        value={(isSaaSCustomer) ? "true" : "false"}
                                    />
                                </FormField>
                            }
                        </SpaceBetween>
                    </Container>
                    <Container
                        header={<Header variant="h2">Partner Information</Header>}
                    >
                        <SpaceBetween size="l">
                            <FormField
                                label="Partner Path"
                                errorText={partnerTypeError}
                                info={
                                    <Link
                                        variant="info"
                                        onFollow={() => props.replaceToolsContent("partnerPathDescription")}
                                    >
                                        Info
                                    </Link>
                                }
                                stretch={true}
                            >
                                <Tiles
                                    onChange={e => {
                                        setPartnerType(e.detail.value);
                                        setPartnerTypeError("");
                                    }}
                                    value={partnerType}
                                    items={PARTNER_TYPES}
                                />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                    <Container
                        header={<Header variant="h2">Workload information</Header>}
                    >
                        <SpaceBetween size="l">
                            <FormField
                                label="Migration or Net New Workload"
                                errorText={migrationError}
                                info={
                                    <Link
                                        variant="info"
                                        onFollow={() => props.replaceToolsContent("workloadDescription")}
                                    >
                                        Info
                                    </Link>
                                }
                                stretch={true}
                            >
                                <RadioGroup
                                    items={WORKLOAD_TYPE}
                                    value={(migration) ? "migration" : "new"}
                                    onChange={e => {
                                        setMigration(e.detail.value === "migration");
                                        setMigrationError("");
                                    }
                                    }
                                />
                            </FormField>

                            <FormField
                                label="Statement of Work/Project Plan costs"
                                description="Enter the USD amount of the Statement of Work/Project Plan."
                                errorText={sowCostError}
                                info={
                                    <Link
                                        variant="info"
                                        onFollow={() => props.replaceToolsContent("sowDescription")}
                                    >
                                        Info
                                    </Link>
                                }
                            >
                                <Input
                                    placeholder="50000"
                                    type="number"
                                    value={sowCost.toString()}
                                    onChange={e => {
                                        setSowCost(Number(e.detail.value));
                                        setSowCostError("");
                                    }
                                    }
                                />
                            </FormField>

                            <FormField
                                label="Projected Monthly Recurring Revenue"
                                description="Enter the USD amount of the projected MRR when the workload goes into production."
                                errorText={mrrError}
                                info={
                                    <Link
                                        variant="info"
                                        onFollow={() => props.replaceToolsContent("mrrDescription")}
                                    >
                                        Info
                                    </Link>
                                }
                            >
                                <Input
                                    placeholder="10000"
                                    type="number"
                                    value={mrr.toString()}
                                    onChange={e => {
                                        setMrr(Number(e.detail.value));
                                        setMrrError("");
                                    }
                                    }
                                />
                            </FormField>
                            {(segment === industryEnum.SUP) &&
                                <FormField
                                    label="Select the service tracks that will be part of the workload."
                                    errorText={servicesListError}
                                    info={
                                        <Link
                                            variant="info"
                                            onFollow={() => props.replaceToolsContent("strategicServiceDescription")}
                                        >
                                            Info
                                        </Link>
                                    }
                                >
                                    <Multiselect
                                        options={JUMPSTART_SERVICES_TRACKS}
                                        placeholder="Select any service tracks included in your workload."
                                        filteringType="auto"
                                        selectedOptions={servicesList}
                                        onChange={e => {
                                            setServicesList(e.detail.selectedOptions);
                                            setServicesListError("");
                                        }}
                                    />
                                </FormField>
                            }
                        </SpaceBetween>
                    </Container>
                    </SpaceBetween>
                </Form>
            </form>
        </div>
    );
}

export default FundingForm;