import {
    fundingTypeEnum,
    IFundingProgram,
    IFundingProgramProps,
    partnerTypeEnum, industryEnum,
    territoryEnum
} from "./FundingProgramInterface";
import {PROGRAM_DETAILS} from "../../constants/ProgramDetails";

export class StartupMigrateAssessFunding implements IFundingProgram {
    private props: IFundingProgramProps;
    public programDetails = PROGRAM_DETAILS.SMAP;
    public fundingAmount: number;
    public fundingType = fundingTypeEnum.ASSESS;

    constructor(props: IFundingProgramProps) {
        this.props = props;
        this.fundingAmount = this.getFundingAmount();
    }

    private getFundingAmount(): number {
        return 10000;
    }

    isApproved(): boolean {
        if (this.props.partnerType !== partnerTypeEnum.SERVICES) {
            return false;
        }

        if (this.props.segment !== industryEnum.SUP) {
            return false;
        }

        if (!this.props.migration) {
            return false;
        }

        if (this.props.mrr > 41000) {
            return false;
        }

        if (this.props.territory === territoryEnum.GRN && this.props.mrr < 5000) {
            return false;
        }

        if (this.props.territory === territoryEnum.ENG && this.props.mrr < 10000) {
            return false;
        }

        return true;

    }

}