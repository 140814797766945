import React from 'react';
import { Header, Table, TableProps } from '@amzn/awsui-components-react';

const createTableSortLabelFn = (
    column: TableProps.ColumnDefinition<unknown>
) => {
    if (
        !column.sortingField &&
        !column.sortingComparator &&
        !column.ariaLabel
    ) {
        return;
    }
    return ({ sorted, descending }) => {
        return `${column.header}, ${
            sorted
                ? `sorted ${descending ? 'descending' : 'ascending'}`
                : 'not sorted'
        }.`;
    };
};

const Accounts: React.FC = () => {
    const columns = [
        {
            id: 'name',
            header: 'Account Name',
            cell: () => 'Pegasystems Inc.',
            sortingField: 'name',
        },
        {
            id: 'psm',
            header: 'PSM Name',
            cell: () => 'Connor Gaffney',
        },
        {
            id: 'alias',
            header: 'Alias',
            cell: () => 'connnogaf',
        },
        {
            id: 'website',
            header: 'Website',
            cell: () => 'pega.com',
        },
        {
            id: 'am',
            header: 'Account Manager',
            cell: () => 'Jennifer Corrigan',
        },
    ];

    const items = [
        {
            name: 'Pegasystems Inc.',
        },
    ];

    const columnDefinitions = columns.map((column) => ({
        ...column,
        ariaLabel: createTableSortLabelFn(column),
    }));

    return (
        <Table
            items={items}
            columnDefinitions={columnDefinitions}
            header={<TableHeader />}
        ></Table>
    );
};

const TableHeader: React.FC = () => {
    return (
        <div>
            <Header variant="awsui-h1-sticky">Startup Partners</Header>
        </div>
    );
};

export default Accounts;
