import {Box, SpaceBetween} from "@amzn/awsui-components-react";
import React from "react";

const TableEmptyState = ({ resourceName }) => (
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box variant="p" color="inherit">
                    No {resourceName.toLowerCase()}s.
                </Box>
            </div>
            {/*<Button>Create {resourceName.toLowerCase()}</Button>*/}
        </SpaceBetween>
    </Box>
);

export default TableEmptyState