export const InMemoryStorage = () => {
    let storage = {};

    return {
        getItem: (key: string) => (storage[key] ? storage[key] : null),
        setItem: (key: string, value: string) => {
            storage[key] = value;
        },
        clear: () => {
            storage = {};
        },
        removeItem: (key: string) => {
            delete storage[key];
        },
    };
};