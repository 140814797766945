import React from "react";
import {ColumnLayout, SpaceBetween} from "@amzn/awsui-components-react";
import {PartnerDefinition} from "../../../interfaces/partner.interface";
import BoxPaddedBottom from "../../common/BoxPaddedBottom";
import {mapDetails} from "../../common/DetailsList";

interface PartnerSpecificDetailsProps {
    partner: PartnerDefinition
}
const PartnerSpecificDetails: React.FC<PartnerSpecificDetailsProps> = ({partner}) => {

    return (
        <ColumnLayout columns={4} variant="text-grid">
            <SpaceBetween size="l" direction="vertical">
                <div>
                    <BoxPaddedBottom variant="awsui-key-label">Segments</BoxPaddedBottom>
                    {mapDetails(partner.segments)}
                </div>
            </SpaceBetween>
            <SpaceBetween size="l" direction="vertical">
                <div>
                    <BoxPaddedBottom variant="awsui-key-label">Workloads</BoxPaddedBottom>
                    {mapDetails(partner.workloads)}
                </div>
            </SpaceBetween>
            <SpaceBetween size="l" direction="vertical">
                <div>
                    <BoxPaddedBottom variant="awsui-key-label">Verticals</BoxPaddedBottom>
                    {mapDetails(partner.verticals)}
                </div>
            </SpaceBetween>
            <SpaceBetween size="l" direction="vertical">
                <div>
                    <BoxPaddedBottom variant="awsui-key-label">AWS Partner Programs</BoxPaddedBottom>
                    {mapDetails(partner.programs)}
                </div>
            </SpaceBetween>
        </ColumnLayout>
    )
}

export default PartnerSpecificDetails;