import {fundingTypeEnum, IFundingProgram, IFundingProgramProps, partnerTypeEnum} from "./FundingProgramInterface";
import {PROGRAM_DETAILS} from "../../constants/ProgramDetails";

export class StartboostFunding implements IFundingProgram {
    private props: IFundingProgramProps;
    public programDetails = PROGRAM_DETAILS.START_BOOST;
    public fundingAmount: number;
    public fundingType = fundingTypeEnum.IMPLEMENT;

    constructor(props: IFundingProgramProps) {
        this.props = props;
        this.fundingAmount = this.getFundingAmount();
    }

    private getFundingAmount(): number {
        const fundingAmount = this.props.mrr * 6;
        if (fundingAmount > 4500) {
            return 4500;
        }

        return fundingAmount;
    }

    isApproved(): boolean {
        if (this.props.partnerType !== partnerTypeEnum.SERVICES) {
            return false;
        }

        return this.props.isSeedStage;
    }
}