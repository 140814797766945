import React, {useState} from "react";
import {Button, Container, FormField, Grid, Header, Input, TokenGroup} from "@amzn/awsui-components-react";

export interface SolutionTagsFormProps {
    tags: string[];
    setTags: (tags: string[]) => void;
}

const SolutionTagsForm: React.FC<SolutionTagsFormProps> = (props) => {

    const [item, setItem] = useState("");

    let selectedTags: {label: string, value: string}[]= [];

    props.tags.forEach((tag) => {
        selectedTags.push({label: tag, value: tag});
    });

    const onTagsChange = (): void => {
        props.setTags([...props.tags, item]);
        setItem("");
    }
    const onTagRemove = (itemIndex: number): void => {
        if (itemIndex > -1) {
            const newSolutionTags = props.tags.filter((_, index) => {
                return index !== itemIndex;
            })
            props.setTags(newSolutionTags);
        }
    }

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    description="Tag the ISV Solution with Key Words to make it more searchable."
                >
                    Tags
                </Header>
            }
        >
            <FormField>
                <Grid gridDefinition={[{colspan: 8}, {colspan: 4}]}>
                    <Input
                        value={item}
                        onChange={(event) =>
                            setItem(event.detail.value)
                        }
                    />
                    <Button
                        formAction="none"
                        onClick={onTagsChange}
                    >
                        Add Tag
                    </Button>
                </Grid>
                <TokenGroup
                    onDismiss={({detail: {itemIndex}}) => {
                        onTagRemove(itemIndex);
                    }}
                    items={selectedTags}
                />
            </FormField>
        </Container>
    )
}

export default SolutionTagsForm;