import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    Alert,
    Box,
    BreadcrumbGroup,
    Button,
    Container,
    ContentLayout,
    Header,
    Modal,
    SpaceBetween,
    Spinner,
} from '@amzn/awsui-components-react';
import { ClickDetail } from '@amzn/awsui-components-react/polaris/internal/events';
import { checkIfAdmin } from '../../../util/AuthService';
import {
    setNotification,
    useDeletePartnerMutation,
    useFetchPartnerQuery,
} from '../../../store';
import PartnerCommonDetails from './PartnerCommonDetails';
import PartnerSpecificDetails from './PartnerSpecificDetails';
import PartnerSpecificSolutions from './PartnerSpecificSolutions';
import { notificationTypeEnum } from '../../../constants/Constants';

const PartnerDetails: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pathName = window.location.pathname;
    const partnerName = pathName.substring(pathName.lastIndexOf('/') + 1);

    const { data: partner, error } = useFetchPartnerQuery(partnerName);

    const [deletePartner] = useDeletePartnerMutation();

    const [isAdmin, setIsAdmin] = useState(false);
    const [visible, setVisible] = React.useState(false);

    useEffect(() => {
        setIsAdmin(checkIfAdmin());
    }, []);

    useEffect(() => {
        if (error && 'data' in error && error.status === 404) {
            navigate('/404');
        }
    }, [error]);

    const deletePartnerEvent = (event: CustomEvent<ClickDetail>) => {
        event.preventDefault();
        if (!partner || !partner.createdAt) {
            return;
        }
        deletePartner({ name: partnerName, createdAt: partner.createdAt })
            .unwrap()
            .then(() => {
                dispatch(
                    setNotification({
                        content: `Successfully deleted ${partnerName}.`,
                        eventType: 'success',
                        notificationType: notificationTypeEnum.DELETEPARTNER,
                    })
                );

                navigate('/partners');
            })
            .catch((error) => {
                console.log(error);
                dispatch(
                    setNotification({
                        content: `Failed to delete ${partnerName}.`,
                        eventType: 'error',
                        notificationType: notificationTypeEnum.DELETEPARTNER,
                    })
                );
            });
    };

    const showDeleteModal = (event: CustomEvent<ClickDetail>): void => {
        event.preventDefault();
        setVisible(true);
    };

    const closeModal = (event: CustomEvent<ClickDetail>): void => {
        event.preventDefault();
        setVisible(false);
    };

    const editPartnerEvent = (event: CustomEvent<ClickDetail>): void => {
        event.preventDefault();
        navigate(`/partners/edit/${partnerName}`);
    };

    const resourceDetailBreadcrumbs = [
        ...resourcesBreadcrumbs,
        {
            text: partner ? partner.name : '',
            href: `/partners/${partnerName}`,
        },
    ];

    let PartnerDetailsComponent: ReactElement;
    if (partner) {
        PartnerDetailsComponent = <PartnerCommonDetails partner={partner} />;
    } else {
        PartnerDetailsComponent = <Spinner />;
    }

    let PartnerSpecificDetailsComponent: ReactElement;
    if (partner) {
        PartnerSpecificDetailsComponent = (
            <PartnerSpecificDetails partner={partner} />
        );
    } else {
        PartnerSpecificDetailsComponent = <Spinner />;
    }

    const Breadcrumbs = () => (
        <BreadcrumbGroup
            items={resourceDetailBreadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
            onClick={(e) => {
                if (!e.detail.external) {
                    e.preventDefault();
                    navigate(e.detail.href);
                }
            }}
        />
    );

    return (
        <div>
            <SpaceBetween size="l">
                <Breadcrumbs />
                <ContentLayout
                    header={
                        <Header
                            variant="h1"
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    {isAdmin ? (
                                        <Button
                                            onClick={(e) => editPartnerEvent(e)}
                                        >
                                            Edit
                                        </Button>
                                    ) : null}
                                    {isAdmin ? (
                                        <Button
                                            onClick={(e) => showDeleteModal(e)}
                                        >
                                            Delete
                                        </Button>
                                    ) : null}
                                </SpaceBetween>
                            }
                        >
                            {partner ? partner.name : ''}
                        </Header>
                    }
                >
                    <SpaceBetween size="l">
                        <Container
                            header={
                                <Header variant="h2">Partner Details</Header>
                            }
                        >
                            {PartnerDetailsComponent}
                        </Container>
                        <Container
                            header={
                                <Header variant="h2">Specific Details</Header>
                            }
                        >
                            {PartnerSpecificDetailsComponent}
                        </Container>
                        <Container
                            header={
                                <Header variant="h2">
                                    Startup Partner Solutions
                                </Header>
                            }
                        >
                            <PartnerSpecificSolutions
                                partner={partner || null}
                            />
                        </Container>
                    </SpaceBetween>
                </ContentLayout>
            </SpaceBetween>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                onClick={(e) => closeModal(e)}
                                variant="link"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={(e) => deletePartnerEvent(e)}
                                variant="primary"
                            >
                                Delete
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Delete partner"
            >
                <p>
                    Permanently delete partner{' '}
                    <b>{partner ? partner.name : ''}</b> and all associated
                    data?
                </p>

                <Alert statusIconAriaLabel="Info">
                    This action will also delete any Solutions associated with
                    this partner.
                </Alert>
            </Modal>
        </div>
    );
};

const resourcesBreadcrumbs = [
    {
        text: 'Partner Hub',
        href: '/',
    },
    {
        text: 'Partners',
        href: '/partners',
    },
];

export default PartnerDetails;
