import React, { useState } from 'react';
import {
    Button,
    Container,
    FormField,
    Header,
    Input,
    Multiselect,
    Select,
    SpaceBetween,
    Textarea,
    Tiles,
    TokenGroup,
} from '@amzn/awsui-components-react';
import {
    ALL_SUB_INDUSTRIES,
    CUSTOMER_INDUSTRY,
    CUSTOMER_SEGMENT,
    GEO,
    SOLUTION_DOMAINS,
    YES_NO,
} from '../../../constants/Constants';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { mapArrayToOptions, mapOptionsToArray } from '../../../util/MapOptions';

export interface SolutionDetailsFormProps {
    title: string;
    setTitle: (title: string) => void;
    description: string;
    setDescription: (description: string) => void;
    geos: string[];
    setGeos: (geos: string[]) => void;
    segments: string[];
    setSegments: (segments: string[]) => void;
    industries: string[];
    setIndustries: (industries: string[]) => void;
    subIndustries: string[];
    setSubIndustries: (subIndustries: string[]) => void;
    subIndustryOptions: OptionDefinition[];
    domain: string;
    setDomain: (domain: string) => void;
    hasMarketplace: boolean;
    setHasMarketplace: (hasMarketplace: boolean) => void;
    marketplaceUrl: string;
    setMarketplaceUrl: (marketplaceUrl: string) => void;
    hasBoost: boolean;
    setHasBoost: (hasBoost: boolean) => void;
    boostUrls: string[];
    setBoostUrls: (boostUrl: string[]) => void;
    titleError: string;
    setTitleError: (titleError: string) => void;
    descriptionError: string;
    setDescriptionError: (descriptionError: string) => void;
    geoError: string;
    setGeoError: (geoError: string) => void;
    segmentError: string;
    setSegmentError: (segmentError: string) => void;
    industryError: string;
    setIndustryError: (industryError: string) => void;
    subIndustryError: string;
    setSubIndustryError: (subIndustryError: string) => void;
    domainError: string;
    setDomainError: (domainError: string) => void;
    marketplaceUrlError: string;
    setMarketplaceUrlError: (marketplaceUrlError: string) => void;
    boostUrlError: string;
    setBoostUrlError: (boostUrlError: string) => void;
}

const SolutionDetailsForm: React.FC<SolutionDetailsFormProps> = (props) => {
    const [item, setItem] = useState('');

    const onTitleChange = (event): void => {
        props.setTitle(event.detail.value);
        props.setTitleError('');
    };

    const onDescriptionChange = (event): void => {
        props.setDescription(event.detail.value);
        props.setDescriptionError('');
    };

    const onGeosChange = (event): void => {
        const geos = mapOptionsToArray(event.detail.selectedOptions);
        props.setGeos(geos);
        props.setGeoError('');
    };

    const onSegmentChange = (event): void => {
        const segments = mapOptionsToArray(event.detail.selectedOptions);
        props.setSegments(segments);
        props.setSegmentError('');
    };

    const onIndustryChange = (event): void => {
        const industries = mapOptionsToArray(event.detail.selectedOptions);
        props.setIndustries(industries);
        props.setIndustryError('');
    };

    const onSubIndustryChange = (event): void => {
        const subIndustry = mapOptionsToArray(event.detail.selectedOptions);
        props.setSubIndustries(subIndustry);
        props.setSubIndustryError('');
    };

    const onDomainChange = (event): void => {
        const domain: string = event.detail.selectedOption.label;
        props.setDomain(domain);
        props.setDomainError('');
    };

    const onMarketPlaceChange = (event): void => {
        const hasMarketplace = event.detail.value === 'true';
        props.setHasMarketplace(hasMarketplace);
    };

    const onMarketPlaceUrlChange = (event): void => {
        props.setMarketplaceUrl(event.detail.value);
        props.setMarketplaceUrlError('');
    };

    const onBoostChange = (event): void => {
        const hasBoost = event.detail.value === 'true';
        props.setHasBoost(hasBoost);
    };

    const onBoostUrlChange = (): void => {
        if (item) {
            props.setBoostUrls([...props.boostUrls, item]);
            props.setBoostUrlError('');
            setItem('');
        } else {
            props.setBoostUrlError('Please enter a valid URL');
        }
    };

    const onBoostRemove = (itemIndex: number): void => {
        if (itemIndex > -1) {
            const newSolutionTags = props.boostUrls.filter((_, index) => {
                return index !== itemIndex;
            });
            props.setBoostUrls(newSolutionTags);
        }
    };

    const selectedGeoOptions = mapArrayToOptions(props.geos, GEO);
    const selectedSegmentOptions = mapArrayToOptions(
        props.segments,
        CUSTOMER_SEGMENT
    );
    const selectedIndustryOptions = mapArrayToOptions(
        props.industries,
        CUSTOMER_INDUSTRY
    );
    const selectedSubIndustryOptions = mapArrayToOptions(
        props.subIndustries,
        ALL_SUB_INDUSTRIES
    );
    const selectedDomainOptions = SOLUTION_DOMAINS.find((obj) => {
        return obj.label === props.domain;
    }) as OptionDefinition;

    let selectedBoostUrls: { label: string; value: string }[] = [];
    props.boostUrls.forEach((url) => {
        selectedBoostUrls.push({ label: url, value: url });
    });

    return (
        <Container header={<Header variant="h2">Solution Details</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <FormField label="Solution Title" errorText={props.titleError}>
                    <Input value={props.title} onChange={onTitleChange} />
                </FormField>

                <FormField
                    label="Solution Description"
                    errorText={props.descriptionError}
                >
                    <Textarea
                        value={props.description}
                        onChange={onDescriptionChange}
                    />
                </FormField>
                <FormField
                    label="Solution Applicable Customer Geos"
                    errorText={props.geoError}
                >
                    <Multiselect
                        options={GEO}
                        selectedOptions={selectedGeoOptions}
                        onChange={onGeosChange}
                    />
                </FormField>
                <FormField
                    label="Solution Applicable Customer Segments"
                    errorText={props.segmentError}
                >
                    <Multiselect
                        options={CUSTOMER_SEGMENT}
                        selectedOptions={selectedSegmentOptions}
                        onChange={onSegmentChange}
                    />
                </FormField>
                <FormField
                    label="Solution Applicable Customer Industries"
                    errorText={props.industryError}
                >
                    <Multiselect
                        options={CUSTOMER_INDUSTRY}
                        selectedOptions={selectedIndustryOptions}
                        onChange={onIndustryChange}
                    />
                </FormField>
                <FormField
                    label="Solution Applicable Customer Sub Industry"
                    errorText={props.subIndustryError}
                >
                    <Multiselect
                        options={props.subIndustryOptions}
                        selectedOptions={selectedSubIndustryOptions}
                        onChange={onSubIndustryChange}
                    />
                </FormField>
                <FormField label="Domain" errorText={props.domainError}>
                    <Select
                        options={SOLUTION_DOMAINS}
                        placeholder="Select the domains this Solution is part of."
                        filteringType="auto"
                        selectedOption={selectedDomainOptions}
                        onChange={onDomainChange}
                    />
                </FormField>
                <FormField label="Has Marketplace Listing">
                    <Tiles
                        onChange={onMarketPlaceChange}
                        value={props.hasMarketplace ? 'true' : 'false'}
                        items={YES_NO}
                    />
                </FormField>
                {props.hasMarketplace && (
                    <FormField
                        label="Marketplace Listing URL"
                        errorText={props.marketplaceUrlError}
                    >
                        <Input
                            value={props.marketplaceUrl}
                            onChange={onMarketPlaceUrlChange}
                        />
                    </FormField>
                )}
                <FormField label="Has Boost Template">
                    <Tiles
                        onChange={onBoostChange}
                        value={props.hasBoost ? 'true' : 'false'}
                        items={YES_NO}
                    />
                </FormField>
                {props.hasBoost && (
                    <div>
                        <FormField
                            label="Boost Template URL"
                            errorText={props.boostUrlError}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexGrow: 1,
                                }}
                            >
                                <div style={{ width: '100%' }}>
                                    <Input
                                        value={item}
                                        onChange={(event) => {
                                            setItem(event.detail.value);
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: '10px',
                                        width: '15%',
                                    }}
                                >
                                    <Button
                                        formAction="none"
                                        onClick={onBoostUrlChange}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </div>
                        </FormField>
                        <div style={{ width: '74%' }}>
                            <TokenGroup
                                onDismiss={({ detail: { itemIndex } }) => {
                                    onBoostRemove(itemIndex);
                                }}
                                items={selectedBoostUrls}
                                alignment="vertical"
                            />
                        </div>
                    </div>
                )}
            </SpaceBetween>
        </Container>
    );
};

export default SolutionDetailsForm;
