import {MapFunding} from "./MapFunding";
import {fundingTypeEnum, IFundingProgramProps} from "./FundingProgramInterface";

export class MapMobilizeFunding extends MapFunding {
    public fundingAmount: number;
    public fundingType = fundingTypeEnum.MOBILIZE;

    constructor(props: IFundingProgramProps) {
        super(props);
        this.fundingAmount = this.getFundingAmount();
    }

    protected getFundingAmount(): number {
        let mobilize = this.props.sowCost / 2;
        let maxMobilize = this.props.mrr * 12 * 0.4;

        if (maxMobilize < mobilize) {
            mobilize = maxMobilize;
        }

        return mobilize;
    }
}