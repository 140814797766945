import {fundingTypeEnum, IFundingProgramProps} from "./FundingProgramInterface";
import {MapLiteFunding} from "./MapLiteFunding";

export class MapLiteAssessFunding extends MapLiteFunding {
    public fundingAmount: number;
    public fundingType = fundingTypeEnum.ASSESS;

    constructor(props: IFundingProgramProps) {
        super(props);
        this.fundingAmount = this.getFundingAmount();
    }

    protected getFundingAmount(): number {
        return 15000;
    }
}