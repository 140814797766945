import Amplify from "@aws-amplify/core";
import {Auth} from "@aws-amplify/auth";
import {CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";
import jwt_decode, {JwtPayload} from "jwt-decode";
import SessionManager from "./SessionManager";
import {amplifyConfig, cognitoUrl} from "../AuthenticatedRoute/Amplify";

Amplify.configure(amplifyConfig);

export const checkAuthTokenExists = (): boolean => {
    const accessTokenExists = !!SessionManager.getAccessToken();
    const idTokenExists = !!SessionManager.getIdToken();
    const refreshTokenExists = !!SessionManager.getRefreshToken();
    return accessTokenExists && idTokenExists && refreshTokenExists;
}
export const login = (
    id_token: string,
    access_token: string,
    refresh_token: string,
    tokenExpiration: string
) => {
    SessionManager.setIdToken(id_token);
    SessionManager.setAccessToken(access_token);
    SessionManager.setRefreshToken(refresh_token);
    SessionManager.setTokenExpiration(tokenExpiration);
};

export const getRoles = (): string[] => {
    const token = SessionManager.getIdToken();
    const decodedToken = jwt_decode<JwtPayload>(token);
    const roles = decodedToken["custom:Role"]
    if (roles) {
        return roles.split(",");
    } else {
        return [];
    }
}

export const checkIfAdmin = () => {
    const roles = getRoles();
    return roles.filter(role => role === "admin").length > 0;
}

export const authenticateUser = async () => {
    let authenticatedUser: CognitoUser | undefined = undefined;
    try {
        authenticatedUser = await Auth.currentAuthenticatedUser();
    } catch (err) {
        console.error(`User not Authenticated. Error: ${err}`);
        window.location.replace(cognitoUrl);
    }
    let userSession: CognitoUserSession | null = null;
    if (authenticatedUser) {
        userSession = authenticatedUser.getSignInUserSession();
    }
    if (userSession) {
        const idToken = userSession.getIdToken();
        const refreshToken = userSession.getRefreshToken();
        const accessToken = userSession.getAccessToken();
        const expirationNumber = idToken.getExpiration();
        const expireDate = new Date(expirationNumber * 1000);

        login(idToken.getJwtToken(), accessToken.getJwtToken(), refreshToken.getToken(), expireDate.toUTCString());
    }

    return authenticatedUser;
}

export const getIdToken = async (): Promise<string> => {
    let userSession: CognitoUserSession | null = null;
    try {
        userSession = await Auth.currentSession();
    } catch (err) {
        console.error(`User not Authenticated. Error: ${err}`);
        window.location.replace(cognitoUrl);
    }
    if (userSession) {
        const idToken = userSession.getIdToken();
        return idToken.getJwtToken();
    } else return "";
}