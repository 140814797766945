import {fundingTypeEnum, IFundingProgram, IFundingProgramProps} from "./FundingProgramInterface";
import {PROGRAM_DETAILS} from "../../constants/ProgramDetails";

export class PocFunding implements IFundingProgram {
    private props: IFundingProgramProps;
    public programDetails = PROGRAM_DETAILS.POC;
    public fundingAmount: number;
    public fundingType = fundingTypeEnum.IMPLEMENT;


    constructor(props: IFundingProgramProps) {
        this.props = props;
        this.fundingAmount = this.getFundingAmount();
    }

    private getFundingAmount(): number {
        const fundingAmount = this.props.mrr * 12 * 0.10;
        if (fundingAmount > 25000){
            return 25000;
        } else {
            return fundingAmount;
        }
    }
    isApproved(): boolean {
        return true;
    }


}