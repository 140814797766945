import { configureStore } from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/dist/query";
import { notificationReducer,
    setNotification,
    resetNotification,
} from "./slices/notificationSlice";
import {partnerApi,
    useFetchAllPartnersQuery,
    useFetchPartnerQuery,
    useAddPartnerMutation,
    useEditPartnerMutation,
    useDeletePartnerMutation,
} from "./apis/partnerApis";
import {solutionApi,
    useFetchAllSolutionsQuery,
    useFetchSolutionQuery,
    useFetchSolutionByPartnerQuery,
    useAddSolutionMutation,
    useEditSolutionMutation,
    useDeleteSolutionMutation,
} from "./apis/solutionApis";


const store = configureStore({
    reducer: {
        notification: notificationReducer,
        [solutionApi.reducerPath]: solutionApi.reducer,
        [partnerApi.reducerPath]: partnerApi.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(solutionApi.middleware)
            .concat(partnerApi.middleware);
    }
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export { store,
    setNotification,
    resetNotification,
    useFetchAllSolutionsQuery,
    useFetchAllPartnersQuery,
    useFetchSolutionByPartnerQuery,
    useAddSolutionMutation,
    useAddPartnerMutation,
    useEditSolutionMutation,
    useEditPartnerMutation,
    useDeleteSolutionMutation,
    useDeletePartnerMutation,
    useFetchPartnerQuery,
    useFetchSolutionQuery,
};