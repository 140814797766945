import { AwsRum }   from "aws-rum-web";

export const appMonitor = ({
    appId,
    guestRoleArn,
    identityPoolId,
    canCollectAnalytics = true,
}) => {
    const APPLICATION_VERION = "1.0.0";
    const APPLICATION_REGION = "us-west-2";
    const config = {
        sessionSampleRate: 1,
        sessionEventLimit: 0,
        sessionLength: 7200,
        allowCookies: canCollectAnalytics,
        enableXRay: true,
        endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
        telemetries: ["errors", "performance", "http"],
        guestRoleArn,
        identityPoolId,
    };

    let monitor;
    if (appId && guestRoleArn && identityPoolId) {
        try {
            monitor = new AwsRum(appId, APPLICATION_VERION, APPLICATION_REGION, config);
        } catch (e) {
            console.log(e);
        }
    }

    return monitor;
};