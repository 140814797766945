import {fundingTypeEnum, IFundingProgramProps} from "./FundingProgramInterface";
import {JumpstartFunding} from "./JumpstartFunding";
import {PROGRAM_DETAILS} from "../../constants/ProgramDetails";

export class DataLabFunding extends JumpstartFunding {
    public programDetails = PROGRAM_DETAILS.DAPP
    public fundingType = fundingTypeEnum.ASSESS;

    constructor(props: IFundingProgramProps) {
        super(props);
        this.fundingAmount = this.getFundingAmount();
    }

    protected getFundingAmount(): number {
        return 10000;
    }

    isApproved(): boolean {
        const hasAnalyticService = this.props.serviceTypes.some(service => service === 'analytics');
        if (!hasAnalyticService || !super.isApproved()) {
            return false;
        }
        return true;
    }
}