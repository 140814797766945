import {
    geoEnum,
    industryEnum,
    partnerTypeEnum,
    subIndustryEnum,
    subSegmentEnum,
    territoryEnum,
} from '../util/funding/FundingProgramInterface'

export const SOLUTION_DOMAINS = [
    { value: 'analytics', label: 'Analytics' },
    { value: 'iot', label: 'IoT' },
    { value: 'ml', label: 'Machine Learning' },
    { value: 'containers', label: 'Containers' },
    { value: 'nosql', label: 'Non-Relational Databases' },
    { value: 'security', label: 'Security' },
    { value: 'migrations', label: 'Migrations' },
    { value: 'chime', label: 'Chime SDK' },
    { value: 'connect', label: 'Amazon Connect' },
    { value: 'serverless', label: 'Serverless' },
    { value: 'genai', label: 'Generative AI' },
]

export interface FormOptionDefinition {
    label: string
    value: string
}

export interface FormOptionWithDescriptionDefinition
    extends FormOptionDefinition {
    description: string
}

export interface SubIndustryMapping {
    industry: string
    subIndustry: FormOptionDefinition[]
}

export const GEO: FormOptionWithDescriptionDefinition[] = [
    {
        label: 'NAMER',
        value: geoEnum.NAMER,
        description: 'Your customer is in the NAMER segment.',
    },

    {
        label: 'LATAM',
        value: geoEnum.LATAM,
        description: 'Your customer is in the LATAM segment.',
    },
    {
        label: 'EMEA',
        value: geoEnum.EMEA,
        description: 'Your customer is in the EMEA segment.',
    },
    {
        label: 'APJ',
        value: geoEnum.APJ,
        description: 'Your customer is in the APJ segment.',
    },
]

export const SUB_FIN_INDUSTRIES: FormOptionDefinition[] = [
    {
        label: 'Banking',
        value: subIndustryEnum.BNK,
    },
    {
        label: 'Capital Markets',
        value: subIndustryEnum.CAP,
    },
    {
        label: 'Insurance',
        value: subIndustryEnum.INS,
    },
    {
        label: 'Payments',
        value: subIndustryEnum.PAY,
    },
]

export const SUB_HCLS_INDUSTRIES: FormOptionDefinition[] = [
    {
        label: 'Healthcare',
        value: subIndustryEnum.HC,
    },
    {
        label: 'Life Sciences',
        value: subIndustryEnum.LS,
    },
]

export const INDUSTRY_MAPPING: SubIndustryMapping[] = [
    {
        industry: industryEnum.FIN,
        subIndustry: SUB_FIN_INDUSTRIES,
    },
    {
        industry: industryEnum.HCLS,
        subIndustry: SUB_HCLS_INDUSTRIES,
    },
]

export const ALL_SUB_INDUSTRIES = [
    ...SUB_FIN_INDUSTRIES,
    ...SUB_HCLS_INDUSTRIES,
]

export const CUSTOMER_INDUSTRY: FormOptionWithDescriptionDefinition[] = [
    {
        label: 'Advertising and Martketing',
        value: industryEnum.AD,
        description:
            'Your customer is in the advertising or marketing industry.',
    },
    {
        label: 'Software and Internet',
        value: industryEnum.SW,
        description: 'Your customer is in the software or internet industry.',
    },
    {
        label: 'Media and Enternainment',
        value: industryEnum.ME,
        description: 'Your customer is in the media or entertainment industry.',
    },
    {
        label: 'Telecommunications',
        value: industryEnum.TELE,
        description: 'Your customer is in the telecommunications industry.',
    },
    {
        label: 'Hi-Tech, Electronics, and Semiconductor',
        value: industryEnum.HT,
        description:
            'Your customer is in the hi-tech, electronics or semiconductor industry.',
    },
    {
        label: 'Manufacturing',
        value: industryEnum.MFG,
        description: 'Your customer is in the manufacturing industry.',
    },
    {
        label: 'Energy and Utilities',
        value: industryEnum.EN,
        description: 'Your customer is in the energy or utilities industry.',
    },
    {
        label: 'Financial Services',
        value: industryEnum.FIN,
        description: 'Your customer is in the financial services industry.',
    },
    {
        label: 'Healthcare and Life Sciences',
        value: industryEnum.HCLS,
        description:
            'Your customer is in the healthcare or life sciences industry.',
    },
    {
        label: 'Automotive',
        value: industryEnum.AUTO,
        description: 'Your customer is in the automotive industry.',
    },
    {
        label: 'Aerospace',
        value: industryEnum.AERO,
        description: 'Your customer is in the aerospace industry.',
    },
    {
        label: 'Travel and Hospitality',
        value: industryEnum.TRAV,
        description: 'Your customer is in the travel or hospitality industry.',
    },
    {
        label: 'Transportation and Logistics',
        value: industryEnum.TRA,
        description:
            'Your customer is in the transportation or logistics industry.',
    },
    {
        label: 'Professional Services',
        value: industryEnum.PRO,
        description: 'Your customer is in the professional services industry.',
    },
    {
        label: 'Justice and Public Safety',
        value: industryEnum.JUST,
        description:
            'Your customer is in the justice or public safety industry.',
    },
    {
        label: 'Agriculture',
        value: industryEnum.AG,
        description: 'Your customer is in the agriculture industry.',
    },
    {
        label: 'Mining',
        value: industryEnum.MIN,
        description: 'Your customer is in the mining industry.',
    },
    {
        label: 'Engineering, Construction, and Real Estate',
        value: industryEnum.RE,
        description:
            'Your customer is in the engineering, construction, or real estate industry.',
    },
    {
        label: 'Games',
        value: industryEnum.GAMES,
        description:
            'Your customer is in the telecom, media, entertainment, gaming, or sports industry.',
    },
    {
        label: 'Retail Consumer Packaged Goods',
        value: industryEnum.CPG,
        description:
            'Your customer is in the consumer packaged goods industry.',
    },
]

export const CUSTOMER_SEGMENT = [
    {
        label: 'Enterprise',
        value: industryEnum.ENT,
        description: 'Your customer is in the enterprise segment.',
    },
    {
        label: 'Digital Native Business',
        value: industryEnum.DNB,
        description: 'Your customer is in the digital native business segment.',
    },
    {
        label: 'Integrated Software Vendor',
        value: industryEnum.ISV,
        description:
            'Your customer is in the integrated software vendor segment.',
    },
    {
        label: 'Small Medium Business',
        value: industryEnum.SMB,
        description: 'Your customer is in the SMB segment.',
    },
    {
        label: 'Greenfield',
        value: industryEnum.GRN,
        description: 'Your customer is in the greenfield segment.',
    },
    {
        label: 'Startup',
        value: industryEnum.SUP,
        description: 'Your customer is in the startup segment.',
    },
]

export const SUB_SEGMENTS: FormOptionDefinition[] = [
    { value: territoryEnum.GRN, label: 'Greenfield' },
    { value: territoryEnum.ENG, label: 'Engaged' },
]

export const TERRITORY: FormOptionDefinition[] = [
    { value: subSegmentEnum.CORE, label: 'Core' },
    { value: subSegmentEnum.PRIORITY, label: 'Priority' },
]

export const WORKLOADS: FormOptionDefinition[] = [
    { value: 'migrations', label: 'Migrations' },
    { value: 'dataandanalytics', label: 'Data & Analytics' },
    { value: 'datalakesandwarehouses', label: 'Data Lakes & Data Warehouses' },
    { value: 'aiml', label: 'AI/ML' },
    { value: 'genai', label: 'Generative AI' },
    { value: 'cicd', label: 'CI/CD' },
    { value: 'devops', label: 'DevOps' },
    { value: 'iot', label: 'IoT' },
    { value: 'security', label: 'Security' },
    { value: 'compliance', label: 'Compliance' },
    { value: 'connect', label: 'Amazon Connect' },
    { value: 'cloudfront', label: 'Amazon CloudFront' },
    { value: 'chimesdk', label: 'Amazon Chime SDK' },
    { value: 'appmod', label: 'Application Modernization' },
    { value: 'database', label: 'Databases' },
    { value: 'drandbackup', label: 'Disaster Recovery & Backup' },
    { value: 'appdev', label: 'Application Development' },
    { value: 'govcloud', label: 'Gov Cloud' },
    { value: 'elemental', label: 'AWS Elemental' },
    { value: 'euc', label: 'EUC' },
    { value: 'fedramp', label: 'FedRamp' },
    { value: 'hipaa', label: 'HIPAA' },
    { value: 'hitrust', label: 'HITRUST' },
    { value: 'staffaug', label: 'Staff Augmentation' },
]

export const VERTICALS: FormOptionDefinition[] = [
    { value: 'healthcare', label: 'Health Care' },
    { value: 'lifescience', label: 'Life Science' },
    { value: 'fintech', label: 'FinTech' },
    { value: 'manufacturing', label: 'Manufacturing' },
    { value: 'isv', label: 'ISV/SaaS' },
    { value: 'mediaandentertainment', label: 'Media & Entertainment' },
    { value: 'gaming', label: 'Gaming' },
    { value: 'retail', label: 'Retail' },
]

export const AWS_PROGRAMS: FormOptionDefinition[] = [
    { value: 'msp', label: 'Managed Service Provider' },
    { value: 'resell', label: 'Resell' },
    { value: 'cppo', label: 'Consulting Partner Private Offer' },
    { value: 'wafr', label: 'Funded WAFR' },
    { value: 'saondemand', label: 'SA on Demand' },
    { value: 'immersiondays', label: 'Immersion Days' },
]

export const JUMPSTART_SERVICES_TRACKS: FormOptionDefinition[] = [
    { value: 'analytics', label: 'Analytics' },
    { value: 'iot', label: 'IoT' },
    { value: 'ml', label: 'Machine Learning' },
    { value: 'containers', label: 'Containers' },
    { value: 'nosql', label: 'Non-Relational Databases' },
]

export const WORKLOAD_TYPE: FormOptionDefinition[] = [
    { label: 'Migration', value: 'migration' },
    { label: 'Net New Workload', value: 'new' },
]

export const PARTNER_TYPES: FormOptionWithDescriptionDefinition[] = [
    {
        label: 'Services',
        value: partnerTypeEnum.SERVICES,
        description:
            'For organizations that deliver consulting, professional, managed, and value-added resale services.',
    },
    {
        label: 'Software',
        value: partnerTypeEnum.SOFTWARE,
        description:
            'For organizations that develop software that runs on or is integrated with AWS.',
    },
]

export const PARTNER_TIERS_SERVICES: FormOptionWithDescriptionDefinition[] = [
    {
        label: 'Premier Tier',
        value: 'Premier Tier',
        description: 'Premier Tier Partner',
    },
    {
        label: 'Advanced Tier',
        value: 'Advanced Tier',
        description: 'Advanced Tier Partner',
    },
    {
        label: 'Select Tier',
        value: 'Select Tier',
        description: 'Select Tier Partner',
    },
    {
        label: 'Registered Tier',
        value: 'Registered Tier',
        description: 'Registered Tier Partner',
    },
]

export const PARTNER_TIERS_SOFTWARE: FormOptionDefinition[] = [
    {
        label: 'Independent Software Vendor',
        value: 'ISV',
    },
    {
        label: 'Independent Software Vendor - Accelerate',
        value: 'ISV-A',
    },
    {
        label: 'SaaS Revenue Recognition Program',
        value: 'SRRP',
    },
]

export const YES_NO: FormOptionDefinition[] = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
]

export enum notificationTypeEnum {
    ADDSOLUTION,
    EDITSOLUTION,
    DELETESOLUTION,
    ADDPARTNER,
    EDITPARTNER,
    DELETEPARTNER,
}
