import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {backendUrl} from "../../AuthenticatedRoute/Amplify";
import {SolutionDefinition} from "../../interfaces/solution.interface";
import {getIdToken} from "../../util/AuthService";

interface solutionResponse {
    url: string;
    key: string;
    bucket: string;
}

interface solutionPutArgs {
    solution: SolutionDefinition;
    solutionId: number;
}

const solutionApi = createApi({
    reducerPath: 'solutions',
    baseQuery: fetchBaseQuery({
        baseUrl: `${backendUrl}`,
        prepareHeaders: async (headers) => {
            const jwt = await getIdToken();
            headers.set('Authorization', jwt)
            return headers;
        }
    }),
    tagTypes: ['solution'],
    endpoints(builder) {
        return {
            fetchAllSolutions: builder.query<SolutionDefinition[], void>({
                providesTags: (result) => {
                    if (result) {
                        let tags: any[];
                        tags = result.map(({id}) => {
                            if (id) {
                                return ({type: 'solution', id})
                            }
                        });
                        tags.push({type: 'solution', id: 'LIST'});
                        return tags;
                    } else {
                        return []
                    }
                },
                query: () => {
                    return {
                        url: '/solutions',
                        method: 'GET'
                    };
                }
            }),
            fetchSolution: builder.query<SolutionDefinition, number>({
                providesTags: (result, _error, solutionId) => {
                    if (result) {
                        return [{type: 'solution', id: solutionId}];
                    } else {
                        return [];
                    }
                },
                query: (solutionId) => {
                    return {
                        url: `/solution/${solutionId}`,
                        method: 'GET',
                    }
                }
            }),
            fetchSolutionByPartner: builder.query<SolutionDefinition[], string>({
                providesTags: () => {
                    return [{type: 'solution', id: 'LIST'}];
                },
                query: (partnerName) => {
                    return {
                        url: `/solutions/${partnerName}`,
                        method: 'GET',
                    }
                }
            }),
            addSolution: builder.mutation<solutionResponse, SolutionDefinition>({
               invalidatesTags: (result) => {
                   if (result) {
                       return [{type: 'solution', id: 'LIST'}, {type: 'solution', id: result.key}]
                   } else {
                       return [{type: 'solution', id: 'LIST'}]
                   }
               },
               query: (solution) => {
                   return {
                       url: '/solutions',
                       body: solution,
                       method: 'POST'
                   }
               }
            }),
            editSolution: builder.mutation<solutionResponse, solutionPutArgs>({
                invalidatesTags: (_result,  _error, {solutionId}) => {
                    return [{type: 'solution', id: 'LIST'}, {type: 'solution', id: solutionId}];
                },
                query: ({solution, solutionId})=> {
                    return {
                        url: '/solution',
                        method: 'PUT',
                        params: {
                            id: solutionId
                        },
                        body: solution
                    }
                }
            }),
            deleteSolution: builder.mutation<void, {solutionId: number, createdAt: number}>({
                invalidatesTags: (_result, _error, {solutionId}) => {
                    return [{type: 'solution', id: 'LIST'}, {type: 'solution', id: solutionId}];
                },
                query: ({solutionId, createdAt}) => {
                    return {
                        url: '/solution',
                        method: 'DELETE',
                        params: {
                            id: solutionId,
                            createdAt: createdAt
                        },
                        responseHandler: (response) => response.text(),
                    }
                }
            }),
        }
    }
});

export const {
    useFetchAllSolutionsQuery,
    useFetchSolutionQuery,
    useFetchSolutionByPartnerQuery,
    useAddSolutionMutation,
    useEditSolutionMutation,
    useDeleteSolutionMutation,
} = solutionApi;
export {solutionApi};