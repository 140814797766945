import React from "react";
import { HelpPanel, Box } from "@amzn/awsui-components-react";

export interface ToolsInterface {
    label: string;
    content: React.JSX.Element;
}

export const Tools: ToolsInterface[] = [
    // Description for the Funding Tool
    {
        label: "fundingToolDescription",
        content: <HelpPanel header={<h2>Partner Funding Program Selector</h2>}>
            <p>
                This tool helps you find the correct partner funding program to help your
                customer finance the Statement of Work/Project Plan costs.
            </p>
        </HelpPanel>
    },
    {
      label: "geoDescription",
      content:
        <HelpPanel header={<h2>Customer Geo Location</h2>}>
            <h4>NAMER</h4>
                <Box variant="p">
                </Box>
            <h4>LATAM</h4>
                <Box variant="p">
                </Box>
            <h4>EMEA</h4>
                <Box variant="p">
                </Box>
            <h4>APJ</h4>
                <Box variant="p">
                </Box>
        </HelpPanel>
    },
    {
        label: "segmentDescription",
        content: <HelpPanel header={<h2>Customer Segment</h2>}>
            <h4>Enterprise</h4>
            <Box variant="p">
                Enterprise companies are commercial accounts defined as:
            </Box>
            <Box variant="p">
                Customer or Prospect with more than $100M company revenue
            </Box>
            <Box variant="p">- OR -</Box>
            <Box>More than 100 employees</Box>
            <Box variant="h4" margin={{ top: "m" }}>
                SMB
            </Box>
            <Box variant="p">SMB companies are commercial accounts defined as:</Box>
            <Box variant="p">
                Customer or Prospect with less than $100M company revenue
            </Box>
            <Box variant="p">- OR -</Box>
            <Box>Fewer than 100 employees</Box>
            <Box variant="p">
                Reseller - SMB customer that has signed a Reseller Agreement
            </Box>
            <Box variant="h4" margin={{ top: "m" }}>
                Startup
            </Box>
            <Box variant="p">Startup companies are commercial accounts defined as:</Box>
            <Box variant="p">
                Affiliated with or has raised capital from an Angel, University,
                Accelerator, Incubator, Venture Capital (VC) firm, Government Startup
                Incubator, or other Startup Organization (as verified by a third party
                startup data source such as CB Insights, Crunchbase, etc.)
            </Box>
            <Box variant="p">- OR -</Box>
            <Box variant="p">
                Customer is affiliated with a Startup discovery program, such as Activate.
            </Box>
            <Box variant="p">- AND -</Box>
            <Box variant="p">
                Customer has less than $3.6M in TTM Gross Amortized Revenue (GAR)
            </Box>
            <Box variant="p">- AND -</Box>
            <Box variant="p">
                Customer is less than 15-years old, based on Company Founding Date
            </Box>
            <Box variant="p">- AND -</Box>
            <Box variant="p">Customer has not IPO'd or been acquired</Box>
        </HelpPanel>,
    },
    {
        label: "customerTypeDescription",
        content:  <HelpPanel header={<h2>Greenfield Customer</h2>}>
            <Box variant="h4">Enterprise</Box>
            <p>
                Your customer is a Greenfield customer if they are charging less than $10k
                ARR
            </p>
            <Box variant="h4">SMB</Box>
            <p>
                Your customer is a Greenfield customer if they are charging less than $10k
                ARR
            </p>
            <Box variant="h4">Startup</Box>
            <p>
                Your customer is a Greenfield customer if they are charging less than $50k
                ARR
            </p>
        </HelpPanel>
    },
    {
        label: "workloadDescription",
        content: <HelpPanel header={<h2>Workload</h2>}>
            <Box variant="p">
                Is your customer moving from on premises or a different cloud provider to
                AWS they are considered to be migrating.
            </Box>
            <Box variant="p">
                Is your customer already on AWS and changing the type of services they are
                using, for example from EC2 to EKS. They are considered to be modernizing.
            </Box>
        </HelpPanel>,
    },
    {
        label: "sowDescription",
        content: <HelpPanel header={<h2>Statement of Work/Project Plan costs</h2>}>
            <Box variant="p">
                The estimated cost given by the partner to complete the migration or
                modernization.
            </Box>
            <Box variant="p">
                This information is required as some funding programs fund a portion of
                the SOW/Project Plan Costs.
            </Box>
        </HelpPanel>
    },
    {
        label: "mrrDescription",
        content: <HelpPanel header={<h2>Projected Monthly Recurring Revenue</h2>}>
            <Box variant="p">
                The estimated monthly recurring revenue on AWS after the work has been
                completed by the partner. This can be estimated using AWS Calculator.
            </Box>
            <Box variant="p">
                Most of our funding programs base the funding amount on the expected MRR.
            </Box>
        </HelpPanel>
    },
    {
        label: "msDescription",
        content: <HelpPanel header={<h2>Is it a Microsoft workload?</h2>}>
            <Box variant="p">
                Are any Microsoft SQL server, .NET applications, and/or Windows Servers
                part of the workload?
            </Box>
            <Box variant="p">
                There are speciality programs for this type of workload.
            </Box>
        </HelpPanel>
    },
    {
        label: "saasDescription",
        content: <HelpPanel header={<h2>Is it a SaaS based company or product?</h2>}>
            <Box variant="p">
                Is the workload part of a SaaS offering or does the company sell a SaaS
                offering?
            </Box>
        </HelpPanel>
    },
    {
        label: "rdsDescription",
        content: <HelpPanel header={<h2>Migration to Aurora or RDS?</h2>}>
            <Box variant="p">
                Is the customer moving any databases that are part of the workload to
                Aurora?
            </Box>
        </HelpPanel>
    },
    {
        label: "strategicServiceDescription",
        content: <HelpPanel header={<h2>Strategic Service Tracks</h2>}>
            <Box variant="p">
                These service tracks are considered strategic services. If your customer plans
                to <b>start</b> using any of the services included in any of these service tracks as part of a
                Migration/Modernization select the service track(s) they are planning to use use.
            </Box>
            <Box variant="p">
                Check the <a href="https://w.amazon.com/bin/view/AWS_Startup_Segment/JumpStart/Services2021/" target="_blank">Wiki </a>
                for more information on which services are included in which service track.
            </Box>

        </HelpPanel>
    },
    {
        label: "partnerPathDescription",
        content: <HelpPanel header={<h2>Partner Path</h2>}>
            <Box variant="p">
                This field is to specify what type of Partner the customer is engaging with
                and will help determine what funding programs are available for that type of Partner.
            </Box>
        </HelpPanel>
    },
    {
        label: "subSegmentDescription",
        content: <HelpPanel header={<h2>Startup Sub Segment</h2>}>
            <Box variant="p">
                This field is to specify what sub segment the customer is in and will determine the
                MRR constraints for the applicable funding programs. Core customers will have CORE in the territory name.
                Priority customers will have PTR in the territory name.
            </Box>
        </HelpPanel>
    },
    {
        label: "saasCustomerDescription",
        content: <HelpPanel header={<h2>SaaS Customer</h2>}>
            <Box variant="p">
                Select "Yes" if your customer is selling a Software as a Service solution to their customers.
            </Box>
        </HelpPanel>
    },
    {
        label: "seedStageDescription",
        content: <HelpPanel header={<h2>Seed Stage Startup</h2>}>
            <Box variant="p">
                A Seed Stage Startup is a customer who has not yet received series A funding or higher.
            </Box>
        </HelpPanel>
    },
    {
        label: "vcTier0Description",
        content: <HelpPanel header={<h2>Tier 0 VCs</h2>}>
            <Box variant="p">

            </Box>
        </HelpPanel>
    }
];