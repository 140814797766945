import React, {ReactElement, useEffect, useState} from "react";
import {
    Alert,
    Box,
    Button,
    ColumnLayout, Container,
    ContentLayout,
    Header, Icon, Modal,
    SpaceBetween, Spinner
} from "@amzn/awsui-components-react";
import {useNavigate} from "react-router-dom";
import {getSolutionPdf} from "../../../util/SolutionService";
import {checkIfAdmin} from "../../../util/AuthService";
import {setNotification, useDeleteSolutionMutation, useFetchPartnerQuery, useFetchSolutionQuery} from "../../../store";
import {skipToken} from "@reduxjs/toolkit/query";
import {SolutionDetails, SolutionExtraInfo, SolutionPartnerDetails, SolutionPeople} from "./index";
import {notificationTypeEnum} from "../../../constants/Constants";
import {useDispatch} from "react-redux";
import { ClickDetail } from "@amzn/awsui-components-react/polaris/internal/events";

interface SolutionProps {
    breadcrumbs: { text: string, href: string }[];
    setBreadcrumbs: (breadcrumbs: { text: string, href: string }[]) => void;
}

const Solution: React.FC<SolutionProps> = ({breadcrumbs, setBreadcrumbs}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pathName = window.location.pathname;
    const id = parseInt(pathName.substring(pathName.lastIndexOf('/') + 1));

    const {data: solution, error} = useFetchSolutionQuery(id);
    const {data: partner} = useFetchPartnerQuery(solution ? solution.partner : skipToken);

    const [deleteSolution] = useDeleteSolutionMutation();

    const [isAdmin, setIsAdmin] = useState(false);
    const [visible, setVisible] = React.useState(false);

    useEffect(() => {
        if (checkIfAdmin()) {
            setIsAdmin(true);
        }
    }, []);

    useEffect(() => {
        if (error && 'data' in error && error.status === 404) {
            navigate('/404');
        }
    }, [error]);

    useEffect(() => {
        if (solution) {
            const solutionDetailBreadcrumb = [
                ...breadcrumbs,
                {
                    text: solution.title,
                    href: `/solutions/${id}`,
                },
            ];
            setBreadcrumbs(solutionDetailBreadcrumb);
        }

    }, [solution])

    const deleteSolutionEvent = (event: CustomEvent<ClickDetail>): void => {
        event.preventDefault();
        if (!solution || !solution.createdAt) {
            return;
        }
        deleteSolution({ solutionId: id, createdAt: solution.createdAt})
            .unwrap()
            .then(() => {
                dispatch(setNotification({
                    content: `Solution ${id} deleted successfully.`,
                    eventType: 'success',
                    notificationType: notificationTypeEnum.DELETESOLUTION
                }));
                navigate('/');
            }).catch((error) => {
                console.error(error);
                dispatch(setNotification({
                    content: `Error deleting solution ${id}.`,
                    eventType: 'error',
                    notificationType: notificationTypeEnum.DELETESOLUTION
            }));
        });
    }

    const showDeleteModal = (event: CustomEvent<ClickDetail>) => {
        event.preventDefault();
        setVisible(true);
    }

    const closeModal = (event: CustomEvent<ClickDetail>): void => {
        event.preventDefault();
        setVisible(false);
    }


    const openPdf = (event: any, id: number) => {
        event.preventDefault();
        getSolutionPdf(id).then(
            (data) => {
                if(data){
                    window.open(data, "_blank")
                } else {
                    console.error("No PDF found.")
                }
            }
        );
    }

    const editSolutionEvent = (event: any) => {
        event.preventDefault();
        navigate(`/solution/edit/${id}`);
    }

    let SolutionDetailsComponent: ReactElement;
    if  (solution) {
        SolutionDetailsComponent = <SolutionDetails solution={solution}/>
    } else {
        SolutionDetailsComponent = <Spinner />
    }
    let SolutionExtraInfoComponent: ReactElement;
    if (solution) {
        SolutionExtraInfoComponent = <SolutionExtraInfo solution={solution}/>
    } else {
        SolutionExtraInfoComponent = <Spinner />
    }
    let partnerComponent: ReactElement;
    if (partner) {
        partnerComponent = <SolutionPartnerDetails partner={partner}/>
    } else {
        partnerComponent = <Spinner />
    }
    let peopleComponent: ReactElement;
    if (solution && partner) {
        peopleComponent = <SolutionPeople solution={solution} partner={partner}/>
    } else {
        peopleComponent = <Spinner />
    }

    return (
        <div>
            <SpaceBetween size="l">
                <ContentLayout
                    header={
                        <Header
                            variant="h1"
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    { isAdmin ? <Button onClick={e => editSolutionEvent(e)}>Edit</Button> : null}
                                    { isAdmin ? <Button onClick={(e) => {showDeleteModal(e)}}>Delete</Button> : null}

                                </SpaceBetween>
                            }
                        >
                            {solution ? solution.title : ""}
                        </Header>
                    }
                >
                    <SpaceBetween size="l">
                    <Container
                        header={<Header variant="h2" actions={
                            <Button variant="primary" onClick={e => openPdf(e, id)}>
                            Download Solution <Icon name="download" />
                            </Button>}>Solution Details</Header>}
                    >
                        <ColumnLayout columns={4} variant="text-grid">
                            {SolutionDetailsComponent}
                            {partnerComponent}
                            {peopleComponent}
                            {SolutionExtraInfoComponent}
                        </ColumnLayout>
                    </Container>
                    </SpaceBetween>
                </ContentLayout>
            </SpaceBetween>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={e => closeModal(e)} variant="link">Cancel</Button>
                            <Button onClick={e => deleteSolutionEvent(e)} variant="primary">Delete</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Delete solution"
            >
                <p>Permanently delete solution <b>{solution ? solution.title : ""}</b>?</p>

                <Alert
                    statusIconAriaLabel="Info"
                >
                    This action will permanently delete the solution.
                </Alert>

            </Modal>

        </div>
    )
}
export default Solution;