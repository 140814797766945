import {IFundingProgramDetails} from "../../constants/ProgramDetails";

export enum geoEnum {
    NAMER = "NAMER",
    EMEA = "EMEA",
    APJ = "APJ",
    LATAM = "LATAM"
}

export enum territoryEnum {
    GRN = "GRN",
    ENG = "ENG"
}

export enum subSegmentEnum {
    PRIORITY = "PRIORITY",
    CORE = "CORE"
}

export enum subIndustryEnum {
    BNK = "BNK",
    CAP = "CAP",
    INS = "INS",
    PAY = "PAY",
    HC = "HC",
    LS = "LS"
}

export enum industryEnum {
    FIN= "FIN",
    HL = "HL",
    AUTO = "AUTO",
    TMEGS = "TMEGS",
    CPG = "CPG",
    SUP = "SUP",
    SMB = "SMB",
    ENT = "ENT",
    DNB = "DNB",
    ISV = "ISV",
    GRN = "GRN",
    AD = "AD",
    SW = "SW",
    ME =  "ME",
    HT = "HT",
    TELE = "TELE",
    MFG = "MFG",
    EN = "EN",
    AERO = "AERO",
    TRAV = "TRAV",
    TRA = "TRA",
    PRO = "PRO",
    JUST = "JUST",
    AG = "AG",
    MIN = "MIN",
    RE = "RE",
    GAMES = "GAMES",
    HCLS = "HCLS"
}

export enum partnerTypeEnum {
    SERVICES = "SERVICES",
    SOFTWARE = "SOFTWARE"
}

export enum fundingTypeEnum {
    ASSESS = "ASSESS",
    MOBILIZE = "MOBILIZE",
    IMPLEMENT = "IMPLEMENT",
}
export interface IFundingProgramProps{
    geo: geoEnum;
    segment: industryEnum;
    subSegment: subSegmentEnum;
    territory: territoryEnum;
    migration: boolean;
    sowCost: number;
    mrr: number;
    partnerType: partnerTypeEnum;
    serviceTypes: string[]
    isSaasCustomer: boolean;
    isSeedStage: boolean;
    t0VcBacked: boolean;
}

export interface IFundingProgram {
    programDetails: IFundingProgramDetails
    fundingAmount: number;
    fundingType: fundingTypeEnum;

    isApproved(): boolean;
}
