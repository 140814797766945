import React from 'react';
import { AppLayout } from '@amzn/awsui-components-react';
import HubNavigation from '../components/hubnavigation';
import { Accounts } from '../components/accounts/accountspage';

const AccountsPage: React.FC = () => {
    return (
        <div>
            <AppLayout
                contentType="table"
                headerSelector="#h"
                content={<Accounts />}
                navigation={<HubNavigation activeHref="/accounts" />}
            />
        </div>
    );
};

export default AccountsPage;
