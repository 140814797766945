import { useSelector } from 'react-redux';
import { RootState } from '../store';
import React, { useEffect, useState } from 'react';
import { notificationTypeEnum } from '../constants/Constants';
import HubNavigation from '../components/hubnavigation';
import PartnerDetails from '../components/partner/partnerdetails';
import { AppLayout } from '@amzn/awsui-components-react';
import Notifications from '../components/common/Notification';

const PartnerDetailPage = () => {
    const notificationType = useSelector(
        (state: RootState) => state.notification.notificationType
    );
    const content = useSelector(
        (state: RootState) => state.notification.content
    );
    const eventType = useSelector(
        (state: RootState) => state.notification.eventType
    );

    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        if (notificationType === notificationTypeEnum.EDITPARTNER) {
            setShowNotification(true);
        }
    }, [notificationType, content, eventType]);

    return (
        <AppLayout
            contentType="wizard"
            headerSelector="#h"
            content={<PartnerDetails />}
            navigation={<HubNavigation activeHref="/partners" />}
            notifications={
                <Notifications
                    showNotification={showNotification}
                    type={eventType}
                    message={content}
                />
            }
        />
    );
};

export default PartnerDetailPage;
