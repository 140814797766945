import React, { useEffect, useState } from 'react';
import { SideNavigation } from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';

interface HubNavigation {
    activeHref?: string;
}

const HubNavigation: React.FC<HubNavigation> = (props) => {
    const [activeHref, setActiveHref] = useState('/');

    const navigate = useNavigate();

    useEffect(() => {
        if (props.activeHref) {
            setActiveHref(props.activeHref);
        }
    }, []);

    return (
        <SideNavigation
            activeHref={activeHref}
            onFollow={(e) => {
                if (!e.detail.external) {
                    e.preventDefault();
                    setActiveHref(e.detail.href);
                    navigate(e.detail.href);
                }
            }}
            header={{ href: '/', text: 'Partner Hub' }}
            items={[
                { type: 'link', text: 'Solutions', href: '/' },
                { type: 'link', text: 'Partners', href: '/partners' },
                // { type: 'link', text: 'Accounts', href: '/accounts' },
                // { type: "link", text: "Funding Selector", href: "/funding" }
            ]}
        />
    );
};

export default HubNavigation;
