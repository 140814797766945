import {IFundingProgram, IFundingProgramProps} from "./FundingProgramInterface";
import {PocFunding} from "./PocFunding";
import {JumpstartFunding} from "./JumpstartFunding";
import {MapFunding} from "./MapFunding";
import {MapLiteFunding} from "./MapLiteFunding";
import {StartupMigrateFunding} from "./StartupMigrateFunding";
import {DataLabFunding} from "./DataLabFunding";
import {SdppFunding} from "./SdppFunding";
import {StartboostFunding} from "./StartboostFunding";
import {BaseCampFunding} from "./BaseCampFunding";
import {MapLiteAssessFunding} from "./MapLiteAssessFunding";
import {MapLiteMobilizeFunding} from "./MapLiteMobilizeFunding";
import {MapMobilizeFunding} from "./MapMobilizeFunding";
import {MapAssessFunding} from "./MapAssessFunding";
import {StartupMigrateAssessFunding} from "./StartupMigrateAssessFunding";

export const getFundingPrograms = (props: IFundingProgramProps): IFundingProgram[] => {
    const allFundingPrograms: IFundingProgram[] = [
        new PocFunding(props),
        new MapLiteAssessFunding(props),
        new MapLiteMobilizeFunding(props),
        new MapLiteFunding(props),
        new MapAssessFunding(props),
        new MapMobilizeFunding(props),
        new MapFunding(props),
        new JumpstartFunding(props),
        new StartupMigrateAssessFunding(props),
        new StartupMigrateFunding(props),
        new DataLabFunding(props),
        new SdppFunding(props),
        new BaseCampFunding(props),
        new StartboostFunding(props),
    ];

    const approvedFundingPrograms = allFundingPrograms.filter(
        fundingProgram => fundingProgram.isApproved()
    );
    console.log(approvedFundingPrograms);
    return approvedFundingPrograms;
}