import FundingForm from "./FundingForm";
import {Tools} from "../tools/Tools";
import React, {useState} from "react";
import {AppLayout} from "@amzn/awsui-components-react";
import HubNavigation from "../hubnavigation";
const FundingPage: React.FC = () => {

    const [toolsOpen, setToolsOpen] = useState(false);
    const [toolsContent, setToolsContent] = useState<React.JSX.Element>(<div></div>);
    const replaceToolsContent = (index: string) => {
        const content = Tools.find((tool) => tool.label === index)?.content;
        if (content !== undefined) {
            setToolsContent(content);
            setToolsOpen(true);
        }
    }

    return (
        <div>
            <AppLayout
                contentType="form"
                headerSelector="#h"
                toolsOpen={toolsOpen}
                tools={toolsContent}
                onToolsChange={({ detail }) => {
                    setToolsOpen(detail.open);
                }}
                navigationOpen
                content={<FundingForm replaceToolsContent={replaceToolsContent}/>}
                navigation={<HubNavigation activeHref="/funding"/>}
            />
        </div>
    )
}



export default FundingPage;