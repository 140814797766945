import {BreadcrumbGroup, BreadcrumbGroupProps} from "@amzn/awsui-components-react";
import React from "react";
import {useNavigate} from "react-router-dom";

interface BreadcrumbsProps {
    resourceDetailBreadcrumbs:  BreadcrumbGroupProps.Item[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({resourceDetailBreadcrumbs}) => {
    const navigate = useNavigate();
    return (
        <BreadcrumbGroup
            items={resourceDetailBreadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
            onClick={ e => {
                if (!e.detail.external) {
                    e.preventDefault();
                    navigate(e.detail.href);
                }
            }}
        />
    )
}
export default Breadcrumbs;